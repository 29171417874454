import React, { useEffect, useState } from 'react';
import { useScroll } from '@/hooks/use-scroll';

export function useIsScrolling() {
	const [isScrolling, setScrolling] = useState<boolean>(false);
	const scroll = useScroll();

	useEffect(() => {
		if (scroll > 0) {
			setScrolling(true);
		} else {
			setScrolling(false);
		}
	}, [scroll]);

	return isScrolling;
}