import { useAxios } from '@/hooks/use-axios';
import React, { ReactNode, useState, useEffect } from 'react';
import { useData } from '@/context/data';
import { useNavigate } from 'react-router-dom';

export type LogoutProps = {
    /**
     * A node to be rendered in the special component.
     */
    children?: ReactNode;
};

export function Logout({ children }: LogoutProps) {
    const { data, fetch } = useData();
    const navigate = useNavigate();

    const [shouldRedirect, setShouldRedirect] = useState(false); // State variable to control redirection

    useAxios({
        url: '/logout',
        onSuccess: () => {
            fetch.post();
        },
        onFinish: () => {
            setShouldRedirect(true);
        }
    }, true);

    useEffect(() => {
        if (shouldRedirect && data) {
            // Redireccionar a la página de inicio
            navigate('/');

            // Recargar la página después de un pequeño retraso para asegurar que la redirección ha ocurrido
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }
    }, [shouldRedirect, data, navigate]);

    return <>{children}</>;
}
