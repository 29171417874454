import React, { ReactNode } from 'react';
import { Form, FormProps } from '@/common/forms/form';
import { Input } from '@/common/inputs/input';
import { BigButton } from '@/ui/button';
import { useData } from '@/context/data';

export type LoginProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
} & FormProps;

export function Login({ children, ...rest }: LoginProps) {
	const { fetch } = useData();

	const onFinish = () => {
		setTimeout(() => {
			window.location.reload();
		}, 100);
    }

	return (
		<Form submit="/auth" callback={onFinish} gap="10px" {...rest}>
			<Input name="username_or_email" label="Identificación" placeholder="Habbux nombre o e-mail" align="end" required />
			<Input name="password" label="Contraseña" type="password" placeholder="Contraseña" align="end" required />
			<Input name="remember_me" type="checkbox" align="end">
				Recuerda mis credenciales
			</Input>
			<BigButton background="#0f7dbc" border="#2a9cde solid">
				Iniciar sesión
			</BigButton>
			{children}
		</Form>
	);
}