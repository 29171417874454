import { Context } from '@/common/forms/form';
import { Box, BoxProps } from '@/common/layout/box';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

export type InputProps = {
	children?: any;
	icon?: ReactElement;
	message?: string | null | undefined;
	placeholder?: string;
	value?: string;
	label?: boolean | string;
	error: boolean;
	labelprops?: any;
	boxprops?: any;
} & React.InputHTMLAttributes<HTMLInputElement> & BoxProps;

export function Input({ children, as="input", type, name, placeholder, value, label, required, labelprops={}, boxprops={}, ...props}: InputProps) {
	const { errors } = useContext(Context)
	const [triggerError, settriggerError] = useState(false)
	const [message, setMessage] = useState<string | null | undefined>(null);

	useEffect(() => {
		if (name) {
			if (errors?.fields?.[name]) {
				settriggerError(true);
				setMessage(errors?.fields?.[name]);
			} else {
				settriggerError(false);
				setMessage(null);
			}
		}
	}, [errors])

	return (
		<Box position="relative" {...boxprops}>
			{label &&
			<Box
				as="label"
				htmlFor={name}
				marginleft="10px"
				background="white"
				width="fit-content"
				fontSize="0.8rem"
				padding="0 12px"
				radius="3px 3px 0 0"
				color="black"
				{...labelprops}
			>
				{label}
			</Box>
			}
			<Box as="label" noselect row width={100} gap="10px" color="#707279" cursor="pointer">
				<Box as={as}
					id={name}
					type={type}
					name={name}
					placeholder={placeholder}
					defaultValue={value}
					required={required}
					data-error={triggerError}
					row={type == 'date' ? true : false}
					width={type == 'checkbox' || type == 'radio' ? 'auto' : 100}
					background="white"
					color="#2b2b2b"
					padding="12px 16px"
					border="1px solid #dddddd"
					radius="5px"
					sfocus={`
						border: 1px solid #6c5ce7;
						background-color: #ffffff;
						& ~ label {
							color: #6c5ce7;
						}
					`}
					splaceholder={`border: 1px solid  #babec9;`}
					sdisabled={`
						cursor: not-allowed;
						background-color: #f6f6f6;
						color: #babec9;
						border: 1px solid #ededed;
					`}
					styles={`
						&[data-error=true] {
							border: 1px solid var(--border-primary-color, #e62e5c);
						}
					`}
					{...props}
				/>
				{children}
			</Box>
			{message &&
			<Box>{message}</Box>
			}
		</Box>
	);
}

Input.defaultProps = {
	type: "text",
	align: "start",
	error: "false"
};