import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Image } from '@/common/content/image';
import { Logo } from '@/ui/logo';
import { List } from '@/common/navigation/nav';
import { Link } from '@/common/navigation/link';
import { useIsMobileCond } from '@/hooks/use-is-mobile-cond';
import { Button } from '@/ui/button';
import { Icon } from '@/common/fonts/icon';
import Udec from './images/udec.png';

export type FooterProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;
};

const Icons = {
	"instagram": "M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z",

	"facebook": "M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z",

	"youtube": "M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
}

export function Footer({ children }: FooterProps) {
	return (
		<Box as="footer"
			background="#000c45"
			color="white"
			placeitems="center"
			padding="4rem"
			gap="2rem"
		>
			<Box columns={useIsMobileCond(1,5)}
				padding="2rem"
				gap="2rem"
				placeitems={useIsMobileCond()}
			>
				<List gap="20px">
					<Logo />
					<Box maxwidth="300px">Comprometidos con la excelencia en ingeniería y la formación de líderes industriales.</Box>
					<Box row gap="5px" margin="12px">
						<Link href="/about-us">Nosotros</Link> |
						<Link href="/news">Noticias</Link> |
						<Link href="/academic-programs">Carrera</Link>
					</Box>
				</List>
				<List gap="5px">
					<Box h4 margin="0 0 20px 0">Soporte Académico</Box>
					<Link href="/contact">Contacto Académico</Link>
					<Link href="https://jefaturaici.udec.cl/">Sitio jefe de carrera</Link>
					<Link href="http://secad.ing.udec.cl/">Secretaría académica</Link>
					<Link href="http://secad.ing.udec.cl/">Horarios de Clases</Link>
				</List>
				<List gap="5px">
					<Box h4 margin="0 0 20px 0">Vida Estudiantil</Box>
					<Link href="https://fi.udec.cl/eventos/">Actividades y Eventos</Link>
					<Link href="https://fi.udec.cl/vinculacion-con-el-medio/">Organizaciones Estudiantiles</Link>
					<Link href="https://fi.udec.cl/movilidad-estudiantil/estudiar-en-el-extranjero/">Programas de Intercambio</Link>
					<Link href="https://fi.udec.cl/movilidad-estudiantil/estudiar-en-la-fi-udec/">Estudiar en la FI</Link>
				</List>
				<List gap="5px">
					<Box h4 margin="0 0 20px 0">Legal</Box>
					<Link href="http://secad.ing.udec.cl/home">Reglamento Docencia</Link>
					{/* <Link href="/privacy-policy">Política de Privacidad</Link>
					<Link href="/ethics">Ética Académica</Link> */}
				</List>
				<List>
					<Box h4 margin="0 0 20px 0">Conéctate</Box>
					<Box row gap="5px" sharedProps={{padding:"0.3rem"}} margin="0 0 20px 0">
						<Button href="https://www.instagram.com/dii_udec/"><Icon path={Icons.instagram} fill="brown"></Icon></Button>
						<Button href="https://www.facebook.com/dii.udec/"><Icon path={Icons.facebook} fill="blue"></Icon></Button>
						<Button href="https://www.youtube.com/channel/UCl_psPRfg7kVkgdjGxy0pfQ"><Icon path={Icons.youtube} fill="red"></Icon></Button>
					</Box>
					{/* <Link href="/terms-and-service">Suscribirse a Newsletter</Link> */}
				</List>
			</Box>
			<Box row={useIsMobileCond()} placeitems={useIsMobileCond()} textcenter>Copyright © 1993 - 2024 Departamento de Ingeniería Industrial</Box>
			<Image src={Udec} />
			<Box center textcenter p>Página web desarrollada con ❤️ por <Link href="https://www.host5.cl">Host5 Hosting </Link>.</Box>
			{children}
		</Box>
	);
}