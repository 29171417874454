import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@/common/layout/box';
import { Menu } from '@/ui/menu';
import { Logo } from '@/ui/logo';
import { useData } from '@/context/data';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { HamburgerButton } from '@/common/buttons/hamburger-button';
import { useIsScrolling } from '@/hooks/use-is-scrolling';

export type HeaderProps = {
	/**
	 * a node to be rendered in the special component.
	 */
};

export function Header({ }: HeaderProps) {
	const { data } = useData();
	const isMobile = useIsMobile();
	const scrolling = useIsScrolling()

	return (
		<Box header background="#252a60" row={isMobile ? false : true} white>
			<Box row placecontent="space-between" placeitems="center">
				<Box row padding="2em">
					<Logo />
				</Box>
				{isMobile && (
					<HamburgerButton onClick={data.toggleNavActive} isActive={data.navActive} background="black" />
				)}
			</Box>
			<Menu />
		</Box>
	);
}