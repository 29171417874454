import React, { ReactNode } from 'react';
import { useData } from '@/context/data';
import { Theme } from '@/common/themes/theme-provider';
import { Box } from '@/common/layout/box';
import { Outlet } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

export type StoreRegisterProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function StoreRegister({ children }: StoreRegisterProps) {

	return (
		<>
			<GlobalStyle />
			<Box
				main
				background="#4DABD7"
				fontFamily={`"Ubuntu Condensed","Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Tahoma,sans-serif;`}
				fontSize="1.03rem"
				fullh>
				<Box article>
					<Outlet />
				</Box>
			</Box>
		</>
	);
}

const GlobalStyle = createGlobalStyle`
html, body, #root {
    overflow: hidden;
}
`