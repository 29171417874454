import React, { ReactNode, useEffect, useState } from 'react';

export function useIsMobile() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		// Detectar si el userAgent contiene alguna cadena común de dispositivos móviles
		const mobileUserAgents = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

		// Función para verificar si el ancho de la ventana es menor a 680px
		const checkWindowWidth = () => {
			const isWindowMobile = window.innerWidth < 680;
			setIsMobile(mobileUserAgents.test(window.navigator.userAgent) || isWindowMobile);
		};

		// Llamar a la función de verificación inicial
		checkWindowWidth();

		// Agregar un listener de evento "resize" para actualizar isWindowMobile cuando cambia el tamaño de la ventana
		window.addEventListener('resize', checkWindowWidth);

		// Limpiar el listener cuando el componente se desmonta
		return () => {
			window.removeEventListener('resize', checkWindowWidth);
		};
	}, []);

	return isMobile;
}
