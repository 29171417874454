import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

export type BaseInertiaLinkProps = {
	as?: string
	data?: any
	href?: string
	method?: any
	headers?: Record<string, string>
	onClick?: (event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => void
	preserveScroll?: any
	preserveState?: any
	replace?: boolean
	only?: string[]
	onCancelToken?: (cancelToken: import('axios').CancelTokenSource) => void
	onBefore?: () => void
	onStart?: () => void
	onProgress?: (progress: any) => void
	onFinish?: () => void
	onCancel?: () => void
	onSuccess?: () => void
	onError?: () => void
	queryStringArrayFormat?: 'indices' | 'brackets'
}

export type AxiosProps = {
	/**
	 * Url where submit
	 */
	url?: string;

	/**
	 * XHR Method
	 */
	method?: any;

	/**
	 * data to pay load
	 */
	values?: any;

	/**
	 * Method that will called before of lauch the post
	 */
	onBefore?: () => void;

	onStream?: (data?: any) => void;

	onUploadProgress?: any;

	onDownloadProgress?: any;

	/**
	 * Method that will called on catching a error
	 */
	onError?: (data?: any) => void;

	/**
	 * Method that will called in cancelling of XHR
	 */
	onCancel?: (data?: any) => void;

	/**
	 * Method that will called in callback of XHR
	 */
	onSuccess?: (data?: any) => void;

	interval?: number;

	preserveScroll?: any;
	preserveState?: any;
} & BaseInertiaLinkProps;

export function useAxios({
	url = '',
	method = 'POST',
	values,
	onUploadProgress,
	onDownloadProgress,
	interval,
	preserveScroll = false,
	preserveState = true,
	...props
	} : AxiosProps = {}, PostOnMount = false) {
	
	const isMounted = useRef(false);
	const [Url, setUrl] = useState<string|undefined>(url);
	const [data, setData] = useState(values);
	const recentlySuccessfulTimeoutId = useRef<any | null>(null);
	const [errors, setErrors] = useState({});
	const [hasErrors, setHasErrors] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [progress, setProgress] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [wasSuccessful, setWasSuccessful] = useState(false);
	const [recentlySuccessful, setRecentlySuccessful] = useState(false);
	useEffect(() => {
		isMounted.current = true
		PostOnMount && post();
		if (interval) {
			const intervalTimer = setInterval(() => post(), interval);
		}
		return () => {
			isMounted.current = false
		}
	}, [])

	const onBefore = () => {
		setLoaded(false)
		setWasSuccessful(false)
		setRecentlySuccessful(false)
		recentlySuccessfulTimeoutId && clearTimeout(recentlySuccessfulTimeoutId.current)
		props.onBefore && props.onBefore()
	};

	const post = (data = {}) => {
		if (Object.keys(data).length) {
			setData(data)
		}
		setLoaded(false)
		setProcessing(true)
		setProgress(null)
		setErrors({})
		setHasErrors(false)
		setWasSuccessful(false)
		setRecentlySuccessful(false)
	}

	const controllerRef = useRef(new AbortController());

	const cancel = () => {
		controllerRef.current.abort();
		props.onCancel && props.onCancel();
	};

	useEffect(() => {
		if (processing && Url) {
			onBefore && onBefore();
			(async () => {
				try {
					const response = await axios.request({
						url: Url,
						method: method,
						data: data,
						signal: controllerRef.current.signal,
						onUploadProgress: onUploadProgress,
						onDownloadProgress: onDownloadProgress
					});
					const contentType = response.headers.get("content-type");
					if (contentType && contentType.indexOf("application/json") !== -1) {
						setData(response.data)
						props.onSuccess && props.onSuccess(response.data);
					}
					
				} catch (errors: any) {
					if (isMounted.current) {
						setProcessing(false)
						setProgress(null)
						setErrors(errors)
						setHasErrors(true)
						props.onError && props.onError(errors.response.data);
					}
					
				} finally {
					setLoaded(true)
					setProcessing(false)
					setProgress(null)
					setErrors({})
					setHasErrors(false)
					setWasSuccessful(true)
					setRecentlySuccessful(true)
					recentlySuccessfulTimeoutId.current = setTimeout(() => {
						if (isMounted.current) {
							setRecentlySuccessful(false)
						}
					}, 2000)
					props.onFinish && props.onFinish();
				}
			})();
		}
	}, [processing, Url]);

	return { cancel, data, errors, post, setUrl, setData, loaded};
}