import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Divider } from '@/common/layout/divider';
import { Link } from '@/common/navigation/link';
import { useAxios } from '@/hooks/use-axios';
import { useMergeState } from '@/hooks/use-merge-state';
import { useIsMobileCond } from '@/hooks/use-is-mobile-cond';

export type NewsProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function News({ children }: NewsProps) {
	const [data, setData] = useMergeState({});

	const NewsList = useAxios({
		url: '/news/list',
		onSuccess: setData,
		values: {
			limit: 5,
			offset: 0
		}
	}, true);

	const urlRegex = /\s/g;

	return (
		<Box article gap6>
			<Box background="#0e3f6a" padding="2rem 2%" gap10 white>
				<Box h1>
					Noticias
				</Box>
				<Box h2 font9>
				Bienvenidos a la sección de noticias del Departamento de Ingeniería Industrial, donde celebramos los avances, compartimos los logros y destacamos las actividades que marcan la diferencia en nuestra comunidad académica y en la industria. Esta plataforma sirve como un punto de encuentro para profesores, estudiantes, egresados y socios industriales para descubrir las últimas tendencias, investigaciones innovadoras y eventos destacados que definen el pulso del DII.
				</Box>
			</Box>
			<Box padding="2rem 2%" gap10 white>
				{data.result && Object.keys(data.result).map((key, i) => (
					<Box
						height="200px"
						key={i}
						background={`linear-gradient(
						rgba(0, 0, 0, 0.5),
						rgba(0, 0, 0, 0.5)
						), url(${data.result[key].image}) center`}
						backgroundcover
					>
						<Box gap10 padding6>
							<Link href={`/article/${data.result[key].title.toLowerCase().replace(urlRegex, '-')}`}>
								<Box h3>{data.result[key].title}</Box>
							</Link>
							<Box p4>{data.result[key].shortstory}</Box>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
}