import React, { ReactNode, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from '@/context/config';
import { WebProvider } from '@/context/web';
import { DataProvider } from '@/context/data';
import { Meta } from '@/app/meta';
import { Router } from '@/app/routes';

export function App() {
	const rootElement = document.getElementById('root');
	if (rootElement) {
		const root = createRoot(rootElement);
		root.render(
			<StrictMode>
				<ConfigProvider>
					<WebProvider>
						<DataProvider>
							<Meta />
							<Router />
						</DataProvider>
					</WebProvider>
				</ConfigProvider>
			</StrictMode>
		)
	}
}

App.displayName = 'Habbux'

App.defaultProps = {};