import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Video } from '@/common/content/video';
import CEEIND from './videos/ceeind.mp4';

export type StudentLifeProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function StudentLife({ children }: StudentLifeProps) {

	return (
		<Box article gap6 textjustify>
			<Box background="#0e3f6a" padding="2rem 2%" gap10 white>
				<Box h1>
					Vida Estudiantil
				</Box>
				<Box h2 font9>
					Bienvenidos a la página de Vida Estudiantil del Departamento de Ingeniería Industrial de la Universidad de Concepción, donde la experiencia universitaria trasciende la excelencia académica para incluir el crecimiento personal, el liderazgo y el compromiso comunitario.
				</Box>
			</Box>
			<Box gap6 padding="2rem 2%">
				<Box p>
					En el Departamento de Ingeniería Industrial, creemos firmemente en el desarrollo integral de nuestros estudiantes. Aquí, no solo te formarás como un ingeniero competente, sino también como un líder visionario y un ciudadano responsable. Nuestras actividades estudiantiles, que van desde proyectos de investigación hasta iniciativas de servicio comunitario, están diseñadas para desafiar tus límites, estimular tu creatividad y fomentar un profundo sentido de responsabilidad social. Te ofrecemos un ambiente donde puedes:
				</Box>
				<Box>
					<Box>Explorar tus pasiones académicas y extracurriculares</Box>
					<Box>Desarrollar habilidades de liderazgo y trabajo en equipo</Box>
					<Box>Participar en proyectos de impacto social y ambiental</Box>
					<Box>Colaborar con una diversa comunidad de estudiantes y profesores</Box>
					<Box>Prepararte para una carrera profesional exitosa y significativa</Box>
					<Box>Construir amistades y conexiones que durarán toda la vida</Box>
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				CEEIND - Centro de Estudiantes de Ingeniería Civil Industrial
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					El CEEIND es el corazón palpitante de nuestra comunidad estudiantil. Con el objetivo de mejorar continuamente la calidad educativa y enriquecer la experiencia universitaria, el Centro de Estudiantes trabaja incansablemente para fortalecer el prestigio de nuestra carrera. Desde liderar proyectos significativos hasta facilitar un espacio para la voz estudiantil, el CEEIND es un pilar de apoyo y motivación para nuestros alumnos.
				</Box>
				{/* <Video src={CEEIND} /> */}
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Actividades y Organizaciones Estudiantiles
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Nuestros estudiantes tienen la oportunidad de sumergirse en una amplia variedad de actividades que complementan su educación académica. Esto incluye clubes de interés, equipos deportivos, grupos de estudio y organizaciones estudiantiles. Estas iniciativas fomentan la colaboración, el liderazgo y las habilidades sociales, preparando a los estudiantes para los desafíos del mundo real.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Eventos y Tradiciones
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Desde ferias de empleo y seminarios con líderes de la industria hasta celebraciones culturales y eventos sociales, la vida estudiantil en el DII es rica y diversa. Nuestros eventos están diseñados para ampliar horizontes, crear recuerdos duraderos y cultivar una comunidad estrechamente unida.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Apoyo Estudiantil y Recursos
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					El bienestar de nuestros estudiantes es primordial. Ofrecemos un conjunto integral de servicios de apoyo, que incluye orientación académica, asesoramiento profesional, salud mental y asistencia financiera. Nuestros recursos están orientados a asegurar que cada estudiante tenga las herramientas necesarias para tener éxito tanto dentro como fuera del aula.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Involúcrate y Marca la Diferencia
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Animamos a nuestros estudiantes a involucrarse activamente en la vida del departamento. A través del voluntariado, la participación en proyectos de investigación y la implicación en iniciativas de sostenibilidad, nuestros estudiantes pueden dejar una huella positiva en la comunidad y más allá.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Historias de Éxito
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Cada año, nuestros estudiantes logran impresionantes logros. En esta sección, destacamos historias de éxito que inspiran y celebran los logros de nuestros estudiantes, desde premios académicos hasta innovaciones emprendedoras.
				</Box>
				<Box p>
					La vida estudiantil en el Departamento de Ingeniería Industrial es una aventura enriquecedora llena de oportunidades, desafíos y descubrimientos. Te invitamos a explorar todo lo que tenemos para ofrecer y a unirte a nosotros para forjar un futuro prometedor.
				</Box>
			</Box>
			{children}
		</Box>
	);
}