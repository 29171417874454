import React, { ReactNode, useEffect } from 'react';
import { Box, BoxProps } from '@/common/layout/box';
import { Link } from '@/common/navigation/link';
import { Nav, List } from '@/common/navigation/nav';
import { HamburgerButton } from '@/common/buttons/hamburger-button';
import { Button } from '@/ui/button';
import { useData } from '@/context/data';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { useToggle } from '@/hooks/use-toggle';

export type MenuProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;

	IconColor?: string;
} & BoxProps;

export function Menu({ children, IconColor = "#09c", ...props }: MenuProps) {
	const { data, setData } = useData();
	const isMobile = useIsMobile();
	const [navActive, toggleNavActive, setNavActive] = useToggle(!isMobile)

	useEffect(() => {
		setData({navActive, toggleNavActive, setNavActive})
	}, [navActive, toggleNavActive, setNavActive]);

	useEffect(() => {
		if (isMobile) {
			setNavActive(false); // Si es un dispositivo móvil, desactivar el Nav
		} else {
			setNavActive(true); // Si no es un dispositivo móvil, activar el Nav
		}
	}, [isMobile]);

	return (
		<>
			{navActive &&
				<Nav
					index={10}
					transition="all 0.3s ease"
					row={isMobile ? false : true}
					spacebetween
					padding="0rem 2%"
					styles={`
						& > ul > li > a  {
						padding: 15px 20px;
						}
					`}
					grow={1}
					{...props}
				>
					<List row={isMobile ? false : true} placeitems={isMobile ? "" : "center"} gap="10px" >
						<Link href="/" gap="10px">
							Inicio
						</Link>
						<Link href="/about-us" gap="10px">
							Acerca Nosotros
						</Link>
						<Link href="/academic-programs" gap="10px">
							Programas Académicos
						</Link>
						<Link href="/research" gap="10px">
							Investigación
						</Link>
						<Link href="/student-life" gap="10px">
							Vida Estudiantil
						</Link>
						<Link href="/news" gap="10px">
							Noticias
						</Link>
						<Link href="/contact-us" gap="10px">
							Contacto
						</Link>
						{
							data.user?.role > 21 &&
							<Link href="/admin" gap="10px">
								Administración
							</Link>
						}
						{children}
					</List>
					{data.loggedIn ? (
						<List row={isMobile ? false : true} placeitems={isMobile ? "" : "center"} gap="10px">
							<Link href="/client" gap="10px">
								Área de cliente
							</Link>
							<Link href="/logout" gap="10px">
								Logout
							</Link>
						</List>
					) : (
						<List row={true} placeitems={isMobile ? "" : "center"} gap="10px">
							<Button
								href="https://admision.udec.cl/ingenieria-civil-industrial/"
								gap="10px"
								background={IconColor}
								color={IconColor == "white" ? "black" : "white"}
								bold
								>
								Admisión
							</Button>
						</List>
					)}
				</Nav>
			}
		</>
	);
}