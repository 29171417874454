import React, { ReactNode } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Box } from '@/common/layout/box';
import { useParams } from 'react-router-dom';
import { useAxios } from '@/hooks/use-axios';
import { useMergeState } from '@/hooks/use-merge-state';

export type ArticleProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

const replace = domNode => {
	if (domNode.type === 'tag' && domNode.name === 'box') {
		const props = Object.keys(domNode.attribs).reduce((acc, key) => {
			// Si el atributo está presente pero no tiene valor, asumimos que es true
			const value = domNode.attribs[key];
			acc[key === 'class' ? 'className' : key] = value === "" ? true : value;
			return acc;
		}, {});

		const children = domToReact(domNode.children, { replace }); // Procesamiento recursivo
		return <Box {...props}>{children}</Box>;
	}
};

export function Article({ children }: ArticleProps) {
	const [data, setData] = useMergeState({});
	let { article } = useParams();

	const urlRegex = /\-/g;
	const _Article = article?.replace(urlRegex, ' ')

	const NewsList = useAxios({
		url: '/news/get/' + _Article,
		onSuccess: setData
	}, true);

	return (
		<Box>
			<Box
				background={`
					linear-gradient(
					rgba(0, 0, 0, 0.5),
					rgba(0, 0, 0, 0.5)
					), url(${data.image})
				`}
				backgroundcover padding="2rem 2%" white gap6>
				<Box h1>{data.title}</Box>
				<Box h2 font9>{data.shortstory}</Box>
			</Box>
			<Box padding="2rem 2%">
				{data.longstory && parse(data.longstory, { replace })}
			</Box>
		</Box>
	);
}
