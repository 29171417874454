import { Box, BoxProps } from '@/common/layout/box';
import React, { Children, ReactNode } from 'react';
import styled from 'styled-components';

export type NavProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;

	item?: BoxProps;
} & BoxProps;

export function Nav({ children, ...rest }: NavProps) {
	return (
		<Box as={NavStyle} {...rest}>
			{children}
		</Box>
	);
}

export function List({ children, item, ...rest }: NavProps) {
	return (
		<Box as="ul" {...rest}>
			{Children.map(children, child =>
				<Item {...item}>
					{child}
				</Item>
			)}
		</Box>
	);
}

export function Item({ children, ...rest }: NavProps) {
	return (
		<Box as="li" {...rest}>
			{children}
		</Box>
	);
}

export const NavStyle = styled.nav`
& > ul {
	list-style: none;
}
`;