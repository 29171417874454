import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Image } from '@/common/content/image';
import { Link } from '@/common/navigation/link';
import Ing from './images/ing.jpg';
import Organigram from './images/organigram.png';
import { useIsMobileCond } from '@/hooks/use-is-mobile-cond';

export type AboutUsProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function AboutUs({ children }: AboutUsProps) {

	return (
		<Box article gap6 textjustify>
			<Box background="#0e3f6a" padding="2rem 2%" gap10 white>
				<Box h1>
					Acerca de Nosotros
				</Box>
				<Box h2 font9>
					Departamento de Ingeniería Industrial - Universidad de Concepción
				</Box>
			</Box>
			<Box gap6 padding="2rem 2%" >
				<Box row={useIsMobileCond()} gap6>
					<Image src={Ing} width="500px" height="400px" />
					<Box gap6>
						<Box h3>
							El Departamento
						</Box>
						<Box p>
							En el corazón del Departamento de Ingeniería Industrial (DII) de la Universidad de Concepción se encuentra una misión trascendental que nos impulsa día a día: educar con pasión, investigar para descubrir y conectar para transformar. Nuestro compromiso es forjar ingenieros civiles industriales que sobresalgan no solo por su competencia técnica y su profundo entendimiento de los complejos sistemas de producción y operaciones, sino que también se destaquen como pioneros y visionarios en su enfoque para resolver problemas.
						</Box>
						<Box p>
							Entendemos que la ingeniería es una fuerza motriz en la evolución de nuestra sociedad, por lo que nuestro enfoque es integral, combinando conocimientos teóricos con prácticas aplicadas y un fuerte énfasis en la ética y la responsabilidad social. Educamos a nuestros estudiantes para que sean críticos, curiosos y resolutivos, dotándolos de las herramientas necesarias para liderar con innovación y para que sean capaces de diseñar estrategias que respondan a los desafíos económicos, ambientales y tecnológicos actuales y futuros.
						</Box>
						<Box p>
							Nuestra labor investigativa no conoce fronteras. Nos adentramos en los últimos avances científicos y tecnológicos con el objetivo de aportar conocimiento significativo y aplicaciones prácticas que propulsen la industria y el bienestar social. Fomentamos una cultura de investigación colaborativa, donde profesores y estudiantes trabajan codo a codo con la industria y la comunidad, generando un flujo constante de ideas innovadoras y soluciones sostenibles.
						</Box>
						<Box p>
							La conexión es esencial en todo lo que hacemos. Nos esforzamos por construir puentes entre nuestra institución y el mundo, estableciendo redes que permitan a nuestros estudiantes y facultad expandir su alcance a través de colaboraciones internacionales, pasantías y programas de intercambio. Estas conexiones fortalecen nuestros programas académicos y enriquecen la experiencia educativa, preparando a nuestros egresados para ser líderes globales en ingeniería.
						</Box>
					</Box>
				</Box>
				<Box p >
					En el DII, estamos comprometidos con una misión que va más allá de la educación tradicional. Nuestro objetivo es inspirar y equipar a las futuras generaciones de ingenieros para que sean capaces de liderar con convicción, actuar con conocimiento y contribuir con soluciones significativas en un mundo en constante transformación.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Visión
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Aspiramos a ser un departamento reconocido a nivel nacional e internacional por nuestra excelencia académica, investigación de vanguardia y contribución significativa al desarrollo tecnológico y económico. Buscamos impulsar el progreso de la ingeniería industrial con un enfoque sostenible y responsable.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Historia
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>		
					El Departamento de Ingeniería Industrial (DII) de la Universidad de Concepción, fundado en 1993, ha evolucionado significativamente a lo largo de sus más de dos décadas de existencia. Como parte integral de una institución con un legado que se remonta a 1919, el DII ha sido testigo y partícipe de numerosos cambios y avances en el campo de la ingeniería industrial.
				</Box>
				<Box p>		
					Desde sus inicios, el DII se enfocó en ofrecer una educación de calidad, con un fuerte énfasis en la creatividad, innovación, y sensibilidad social. Sus programas de pregrado y postgrado han estado siempre alineados con las necesidades del sector industrial, tanto a nivel nacional como internacional.
				</Box>
				<Box p>		
					El crecimiento del DII se puede atribuir a varios factores. Primero, su capacidad para adaptarse y evolucionar junto con los avances tecnológicos y las tendencias del mercado. Esto se refleja en su currículum y metodologías de enseñanza, que se han mantenido actualizados y relevantes.
				</Box>
				<Box p>
					Segundo, la calidad de su cuerpo docente, compuesto mayoritariamente por profesores con doctorados y maestrías, ha sido un pilar fundamental. Esta excelencia académica ha permitido al departamento mantener un alto nivel de investigación y desarrollo, contribuyendo significativamente al conocimiento en el campo de la ingeniería industrial.
				</Box>
				<Box p>
					Tercero, la colaboración con la industria ha sido una constante. A través de alianzas estratégicas y proyectos conjuntos, el DII ha logrado vincular la teoría con la práctica, proporcionando soluciones innovadoras a problemas reales del sector.
				</Box>
				<Box p>
					Además, la participación activa en proyectos como "Ingeniería 2030" de Corfo ha impulsado aún más su desarrollo, permitiendo al DII posicionarse como un referente en la formación de ingenieros capaces de abordar problemas sociales y económicos complejos.
				</Box>
				<Box p>
					El compromiso con la diversidad y pluralidad también ha sido un aspecto clave. El ambiente inclusivo y enriquecedor del DII ha permitido a sus estudiantes y profesores trabajar en un contexto de respeto y apertura a distintas perspectivas y enfoques.
				</Box>
				<Box p>
					En resumen, el Departamento de Ingeniería Industrial de la Universidad de Concepción ha logrado consolidarse como una institución líder en Chile, caracterizada por su compromiso con la excelencia, la innovación, y la responsabilidad social. Su historia es una de crecimiento continuo, adaptación y contribución significativa tanto al ámbito académico como al industrial.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Nuestros Valores
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box gap10>
					<Box p><Box bold>Innovación:</Box> Promovemos un pensamiento que desafía los límites tradicionales y fomenta soluciones creativas.</Box>
					<Box p><Box bold>Integridad:</Box> Nos adherimos a los más altos estándares éticos en todas nuestras actividades.</Box>
					<Box p><Box bold>Colaboración:</Box> Valoramos el trabajo en equipo y la colaboración tanto dentro como fuera de nuestra comunidad académica.</Box>
					<Box p><Box bold>Diversidad e Inclusión:</Box> Nos comprometemos a crear un ambiente inclusivo que respeta y celebra la diversidad de personas e ideas.</Box>
					<Box p><Box bold>Excelencia:</Box> Nos dedicamos a la búsqueda continua de la excelencia en enseñanza, investigación y extensión.</Box>
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Nuestra Misión
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					En el Departamento de Ingeniería Industrial (DII) de la Universidad de Concepción, nuestra misión es educar, investigar y conectar. Nos esforzamos por formar ingenieros civiles industriales que no solo sean excelentes en su campo técnico, sino que también sean líderes innovadores y agentes de cambio en la sociedad.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Nuestro Equipo
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Contamos con un equipo de profesores y profesionales altamente calificados, muchos de ellos con doctorados y experiencia internacional. Nuestro personal está comprometido con la educación de calidad y la orientación personalizada de nuestros estudiantes.
				</Box>
				<Image src={Organigram} center />
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Infraestructura y Recursos
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Nuestras modernas instalaciones incluyen laboratorios especializados, salas de estudio y espacios de colaboración. Los estudiantes tienen acceso a recursos tecnológicos de vanguardia y una biblioteca extensa para apoyar su aprendizaje y proyectos de investigación.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Impacto y Alcance
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					El impacto de nuestro trabajo se extiende más allá del aula. Nuestros proyectos de investigación han contribuido al desarrollo de soluciones industriales innovadoras, mientras que nuestros programas de extensión y vinculación con el medio fortalecen la relación entre la universidad y la comunidad.
				</Box>
			</Box>
			<Box section padding="4rem 5%" background="#326ea1" gap6 center white>
				<Box h2 font5>
					Únete a Nosotros
				</Box>
				<Box p4 textcenter>
					Te invitamos a conocer más sobre nuestro departamento, explorar nuestros programas académicos y descubrir cómo puedes formar parte de nuestra comunidad de ingenieros industriales líderes.
				</Box>
			</Box>
			{children}
		</Box>
	);
}