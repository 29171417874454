import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button as BaseButton, ButtonProps } from '@/common/buttons/button';

export function Button({ ...props }: ButtonProps) {
	return (
		<BaseButton
		background="white"
		padding="0.844em 1em"
		radius="8px"
		border="1px solid #ededed"
		{ ...props }
		>
		</BaseButton>
	)
};

export const ButtonStyle = styled(BaseButton)`
&:hover {
	background-color: var(--bg-heavy, #f6f6f6);
}
&:active {
	background-color: var(--border-medium-color, #ededed);
}
&:disabled {
	color: var(--border-high-color, #babec9);
	cursor: not-allowed;
}
&:focus {
	outline: none;
	box-shadow: 0 0 0 0.125em var(--bit-accent-bg, #eceaff);
}
`;

export function BigButton({ ...props }: ButtonProps) {
	return (
		<Button
		background="#00813e"
		border="#8eda55 solid"
		color="white"
		radius="5px"
		filter="drop-shadow(-1px 4px 0 rgba(0, 0, 0, .3))"
		fontfamily={`"Ubuntu Condensed","Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Tahoma,sans-serif;`}
		font10
		{ ...props }
		>
		</Button>
	)
};