import React, { ReactNode } from 'react';
import { Button, ButtonProps } from '@/common/buttons/button';

export type HamburgerButtonProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;

	isActive?: boolean;
	
	background?: string;
} & ButtonProps;

export function HamburgerButton({ children, isActive, background, ...props }: HamburgerButtonProps) {

	return (
		<Button relative padding="10px" placecontent="space-between" width="45px" gap="2px"
			styles={`
				&:focus {
					outline: none;
				}

				span {
					width: 100%;
					height: 7px;
					background-color: ${background};
					transition: 0.4s;

					&:first-child {
					transform: ${isActive ? 'rotate(-45deg)' : 'rotate(0)'};
					transform-origin: right center;
					}

					&:nth-child(2) {
					opacity: ${isActive ? '0' : '1'};
					}

					&:last-child {
					transform: ${isActive ? 'rotate(45deg)' : 'rotate(0)'};
					transform-origin: right center;
					}
				}
			`}
			{...props}
		>
			<span></span>
			<span></span>
			<span></span>
			{children}
		</Button>
	);
}