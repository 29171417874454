import React, { ReactNode, useRef } from 'react';
import { Outlet } from 'react-router-dom'
import { Box, BoxProps } from '@/common/layout/box';
import { Header } from '@/ui/header';
import { Menu } from '@/ui/menu';
import { Footer } from '@/ui/footer';
import { useIsScrollDetection } from '@/hooks/use-is-scroll-detection';
import { useData } from '@/context/data';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { useIsScrolling } from '@/hooks/use-is-scrolling';
import { HamburgerButton } from '@/common/buttons/hamburger-button';

export type StoreFrontProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function StoreFront({ children }: StoreFrontProps) {
	const { data } = useData();
	const isMobile = useIsMobile();
	const isScrolling = useIsScrolling();

	return (
		<Box main
			fontFamily={`"Ubuntu Condensed","Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Tahoma,sans-serif;`}
			fontSize="1.03rem"
			fullh
		>
			<Header />
			<Outlet />
			<Footer />
		</Box>
	)
}