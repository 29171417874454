import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';

export type ResearchProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function Research({ children }: ResearchProps) {

	return (
		<Box article gap6 textjustify>
			<Box background="#0e3f6a" padding="2rem 2%" gap10 white>
				<Box h1>
					Investigación
				</Box>
				<Box h2 font9>
				Bienvenidos al epicentro de la investigación del Departamento de Ingeniería Industrial de la Universidad de Concepción, donde el rigor científico se encuentra con la innovación para forjar el futuro de la ingeniería. Nuestro compromiso con la excelencia investigativa es inquebrantable, y se refleja en la diversidad y profundidad de nuestros proyectos de investigación.
				</Box>
			</Box>
			<Box gap6 padding="2rem 2%" >
				<Box p>
					Nos enorgullecemos de una amplia gama de áreas de investigación que abarcan desde la optimización de sistemas hasta la gestión de la tecnología y la sostenibilidad. Cada grupo de investigación, dirigido por académicos destacados, aborda retos globales y desarrolla soluciones que impulsan el avance tecnológico y mejoran la calidad de vida. Entre nuestras áreas de enfoque se encuentran:
				</Box>
				<Box>
					<Box>Optimización y Modelado Matemático</Box>
					<Box>Sistemas de Producción y Logística</Box>
					<Box>Ingeniería de la Sostenibilidad</Box>
					<Box>Análisis de Datos y Big Data</Box>
					<Box>Innovación y Gestión Tecnológica</Box>
					<Box>Economía de la Ingeniería y Finanzas Corporativas</Box>
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Impacto y Colaboración
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					La investigación en el DII no se limita a las fronteras del campus: trasciende hacia colaboraciones internacionales con universidades, centros de investigación y la industria. Estas asociaciones fortalecen nuestro impacto y permiten que nuestras innovaciones alcancen mercados globales y contribuyan a la sociedad en múltiples niveles.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Publicaciones y Reconocimientos
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Nuestros investigadores no solo comparten sus hallazgos en revistas y conferencias de renombre internacional sino que también obtienen premios y reconocimientos que reflejan la calidad y relevancia de su trabajo. Esta sección ofrece acceso directo a las publicaciones más recientes y destacadas del departamento.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Fomentando Futuros Investigadores
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Estamos comprometidos con la formación de la próxima generación de investigadores. Ofrecemos programas de posgrado y oportunidades de investigación para estudiantes que deseen unirse a nuestros esfuerzos para avanzar en la frontera del conocimiento.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Últimas Noticias de Investigación
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Manténgase al día con los últimos desarrollos y descubrimientos visitando nuestra sección de noticias, actualizada regularmente con los avances de nuestros equipos de investigación.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Conéctese con Nuestros Expertos
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box p>
					Si está interesado en colaborar, obtener más información sobre un área específica de investigación o discutir oportunidades de financiamiento, no dude en contactar a nuestros investigadores directamente.
				</Box>
			</Box>
			{children}
		</Box>
	);
}