import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom'
import { Box, BoxProps } from '@/common/layout/box';
import { Icon } from '@/common/fonts/icon';
import { Input } from '@/common/inputs/input';
import { Menu } from '@/ui/menu';
import { Footer } from '@/ui/footer';
import { Button } from '@/ui/button';
import { Logo } from '@/ui/logo';
import Cover from './images/2.jpg';
import { useData } from '@/context/data';
import { HamburgerButton } from '@/common/buttons/hamburger-button';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { useIsScrolling } from '@/hooks/use-is-scrolling';
import { useIsMobileCond } from '@/hooks/use-is-mobile-cond';
import Video from './videos/video.mp4';

export type StoreHomeProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function StoreHome({ children }: StoreHomeProps) {

	return (
		<Box as="main"
			fontFamily={`"Ubuntu Condensed","Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Tahoma,sans-serif;`}
			fontSize="1.03rem"
		>
			<Header />
			<Outlet />
			<Footer />
		</Box>
	)
}

const Icons = {
	"down": "M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z",

	"play": "M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z",

	"left": "M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z",

	"right": "M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z",

	"volume": "M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z",

	"gear": "M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z",

	"expand": "M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z",

	"caption": "M0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM200 208c14.2 0 27 6.1 35.8 16c8.8 9.9 24 10.7 33.9 1.9s10.7-24 1.9-33.9c-17.5-19.6-43.1-32-71.5-32c-53 0-96 43-96 96s43 96 96 96c28.4 0 54-12.4 71.5-32c8.8-9.9 8-25-1.9-33.9s-25-8-33.9 1.9c-8.8 9.9-21.6 16-35.8 16c-26.5 0-48-21.5-48-48s21.5-48 48-48zm144 48c0-26.5 21.5-48 48-48c14.2 0 27 6.1 35.8 16c8.8 9.9 24 10.7 33.9 1.9s10.7-24 1.9-33.9c-17.5-19.6-43.1-32-71.5-32c-53 0-96 43-96 96s43 96 96 96c28.4 0 54-12.4 71.5-32c8.8-9.9 8-25-1.9-33.9s-25-8-33.9 1.9c-8.8 9.9-21.6 16-35.8 16c-26.5 0-48-21.5-48-48z",

	"pause": "M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z",

	"compress": "M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z",

	"angleleft": "M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z",

	"muted": "M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"
}

export function Header({ children }: StoreHomeProps) {
	const { data } = useData();
	const isMobile = useIsMobile();
	const scrolling = useIsScrolling()
	const videoRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [volume, setVolume] = useState(0.5);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [subtitlesEnabled, setSubtitlesEnabled] = useState(false);
	const [isSettings, setIsSettings] = useState(false);
	const [settingsView, setSettingsView] = useState('default'); // 'subtitles', 'speed', 'quality'
	const [showControls, setShowControls] = useState(true);
	const [isCursorVisible, setIsCursorVisible] = useState(true);
	const inactivityTimeout = useRef();
	const controlsTimerRef = useRef(null);

	const boxProps: BoxProps = {
		index: 10,
		position: 'fixed',
		width: '100vw',
		background: (scrolling || isMobile) ? 'white' : 'linear-gradient(180deg, #0000004f, transparent)',
		color: (scrolling || isMobile) ? 'black' : 'white',
		transition: 'all 0.3s ease',
		styles: `
			border-bottom: ${scrolling ? '1px solid rgba(0, 0, 0, 0.2)' : '1px solid #0000004a'};
		`,
	};

	const togglePlayPause = () => {
		if (videoRef.current) {
			if (isPlaying) {
				videoRef.current.pause();
			} else {
				videoRef.current.play();
			}
			setIsPlaying(!isPlaying);
		}
	};

	const handleTimeUpdate = () => {
		if (videoRef.current) {
			const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
			setProgress(progress);
		}
	};

	const handleProgressChange = (event) => {
		const newProgress = event.target.value;
		const newTime = (videoRef.current.duration * newProgress) / 100;
		videoRef.current.currentTime = newTime;
		setProgress(newProgress);
	};

	const handleVolumeChange = (event) => {
		const newVolume = event.target.value / 100; // El valor del control deslizante es entre 0 y 100
		if (videoRef.current) {
			videoRef.current.volume = newVolume;
		}
		setVolume(newVolume);
	};

	const formatTime = (seconds) => {
		const roundTime = Math.round(seconds);
		const hours = Math.floor(roundTime / 3600);
		const minutes = Math.floor((roundTime - hours * 3600) / 60);
		const secs = roundTime - hours * 3600 - minutes * 60;
		return `${hours > 0 ? `${hours}:` : ''}${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
	};

	const skipForward = () => {
		if (videoRef.current) {
			videoRef.current.currentTime += 10; // Adelanta 10 segundos
		}
	};

	const skipBackward = () => {
		if (videoRef.current) {
			videoRef.current.currentTime -= 10; // Retrocede 10 segundos
		}
	};

	const toggleFullScreen = () => {
		if (videoRef.current) {
			if (document.fullscreenElement) {
				document.exitFullscreen();
				setIsFullScreen(false); // Actualizar estado cuando se sale de pantalla completa
			} else {
				videoRef.current.parentNode.requestFullscreen().then(() => {
					setIsFullScreen(true); // Actualizar estado cuando entra en pantalla completa
				}).catch(err => {
					console.error(err); // Manejar errores, como el usuario cancelando la solicitud
				});
			}
		}
	};

	const enableSubtitles = (preferredLanguage) => {
		const tracks = videoRef.current.textTracks;
		let found = false;

		for (let i = 0; i < tracks.length; i++) {
			if (tracks[i].language === preferredLanguage) {
				tracks[i].mode = 'showing';
				found = true;
				setSubtitlesEnabled(true);
				break;
			}
		}

		if (!found) {
			for (let i = 0; i < tracks.length; i++) {
				if (tracks[i].mode === 'disabled') {
					tracks[i].mode = 'showing';
					setSubtitlesEnabled(true);
					break;
				}
			}
		}
	};

	const disableSubtitles = () => {
		const tracks = videoRef.current.textTracks;

		for (let i = 0; i < tracks.length; i++) {
			tracks[i].mode = 'disabled';
		}
		setSubtitlesEnabled(false);
	};

	const toggleSubtitles = () => {
		if (subtitlesEnabled) {
			disableSubtitles();
		} else {
			enableSubtitles('en'); // Reemplaza 'en' con la preferencia del usuario
		}
	};

	const setPlaybackSpeed = (speed) => {
		if (videoRef.current) {
			videoRef.current.playbackRate = speed;
		}
	};

	const handleBackClick = () => {
		switch (settingsView) {
			case 'subtitles':
			case 'speed':
			case 'quality':
				setSettingsView('default');
				break;
			default:
				setIsSettings(false)
				break;
		}
	};

	const handleMouseMove = () => {
		// Mostrar el cursor y los controles cuando se mueve el mouse
		setIsCursorVisible(true);
		setShowControls(true);

		// Limpiar el timeout anterior y configurar uno nuevo
		clearTimeout(inactivityTimeout.current);
		inactivityTimeout.current = setTimeout(() => {
			// Ocultar el cursor y los controles después de 2 segundos de inactividad
			setIsCursorVisible(false);
			// Si settings está desactivado, también ocultamos los controles
			if (!isSettings) {
				setShowControls(false);
			}
		}, 2000); // 2000 milisegundos = 2 segundos
	};

	useEffect(() => {
		// Limpiar el temporizador cuando el componente se desmonte
		return () => {
			if (controlsTimerRef.current) {
				clearTimeout(controlsTimerRef.current);
			}
		};
	}, []);

	useEffect(() => {
		const videoElement = videoRef.current;

		// Actualizar el estado de la duración cuando el video esté cargado
		const setVideoDuration = () => {
			setDuration(videoElement.duration);
		};

		// Actualizar el tiempo actual de reproducción
		const handleTimeUpdate = () => {
			setCurrentTime(videoElement.currentTime);
			const progress = (videoElement.currentTime / videoElement.duration) * 100;
			setProgress(progress);
		};

		// Event listener para el control del teclado
		const handleKeyPress = (event) => {
			if (!videoRef.current) return;

			// Adelantar con la flecha derecha
			if (event.keyCode === 39) {
				videoRef.current.currentTime += 5;
			}

			// Atrasar con la flecha izquierda
			if (event.keyCode === 37) {
				videoRef.current.currentTime -= 5;
			}
		};

		const handleFullScreenChange = () => {
			const isFull = document.fullscreenElement === videoRef.current.parentNode;
			setIsFullScreen(isFull);
		};

		videoElement.addEventListener('loadedmetadata', setVideoDuration);
		videoElement.addEventListener('timeupdate', handleTimeUpdate);
		document.addEventListener('keydown', handleKeyPress);
		document.addEventListener('fullscreenchange', handleFullScreenChange);
		document.addEventListener('mousemove', handleMouseMove);

		return () => {
			videoElement.removeEventListener('loadedmetadata', setVideoDuration);
			videoElement.removeEventListener('timeupdate', handleTimeUpdate);
			document.removeEventListener('keydown', handleKeyPress);
			document.removeEventListener('fullscreenchange', handleFullScreenChange);
			document.removeEventListener('mousemove', handleMouseMove);
			clearTimeout(inactivityTimeout.current);
		};
	}, []);

	return (
		<>
			<Box row={isMobile ? false : true} {...boxProps}>
				<Box row placecontent="space-between" placeitems="center">
					<Box row padding="2em" color={(scrolling || isMobile) ? '#2185c3' : 'white'}>
						<Logo />
					</Box>
					{isMobile && (
						<HamburgerButton onClick={data.toggleNavActive} isActive={data.navActive} background="black" />
					)}
				</Box>
				<Menu IconColor={(scrolling || isMobile) ? '#09c' : 'white'} />
			</Box>
			<Box
				height="100vh"
				maxheight="100vh"
				overflow="hidden"
				background={`url(${Cover}) center / cover no-repeat`}
			>
				{!isMobile &&
					<Box
						relative
						width="100vw"
						visibility={progress ? 'visible' : 'hidden'}
						height={progress ? '100vh' : '0'}
						onMouseMove={handleMouseMove}
						cursor={isCursorVisible ? 'default' : 'none'}
						overflow={(!isPlaying && progress < 10 && progress > 90) && "hidden"}
						styles={`
							& video {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								min-width: 100%;
								min-height: 100%;
								width: auto;
								height: auto;
							}
						`}
					>
						<video src={Video} ref={videoRef}></video>
						{showControls && isFullScreen &&
							(<Box absolute fullw fullh>
								<Box absolute top="25px" left="25px">
									<Box h1 font8 white></Box>
								</Box>
								<Box fullh onClick={togglePlayPause}>
									{!isPlaying &&
										<Box fullh center>
											<Icon path={Icons.play} fill="#fff" size="6em" viewBox="-250 -120 800 800" border="1px solid white" radius="100%" />
										</Box>
									}
								</Box>
								{isSettings &&
									<Box background="#0000007a" place="end" width="30%" absolute fullh white padding3 gap5>
										<Box row>
											<Icon path={Icons.angleleft} onClick={handleBackClick} size="3em" fill="white" />
											<Box h2 fullw centerh textAlign="end">{settingsView === 'default' ? 'Configuración' : settingsView === 'subtitles' ? 'Subtítulos' : settingsView === 'speed' ? 'Velocidad' : 'Calidad'}</Box>
										</Box>
										<Box gap10 font8 place="end" textAlign="end">
											{settingsView === 'default' && (
												<>
													<Box onClick={() => setSettingsView('subtitles')}>Subtitulos</Box>
													<Box onClick={() => setSettingsView('speed')}>Velocidad</Box>
													<Box onClick={() => setSettingsView('quality')}>Calidad</Box>
												</>
											)}
											{settingsView === 'subtitles' && (
												<>
													{Array.from(videoRef.current.textTracks).map((track, index) => (
														<Box key={index} onClick={() => selectSubtitle(track.language)}>
															{track.label}
														</Box>
													))}
												</>
											)}
											{settingsView === 'speed' && (
												<>
													{[0.5, 1, 1.5, 2].map(speed => (
														<Box key={speed} onClick={() => setPlaybackSpeed(speed)}>
															{speed}x
														</Box>
													))}
												</>
											)}
											{settingsView === 'quality' && (
												<>
													{['360p', '720p', '1080p'].map(quality => (
														<Box key={quality} onClick={() => changeVideoQuality(quality)}>
															{quality}
														</Box>
													))}
												</>
											)}
										</Box>
									</Box>
								}
								<Box padding10 gap10>
									<Box height="5px" fullw place="end">
										<Input
											type="range"
											min="0"
											max="100"
											padding="0"
											value={progress}
											onChange={handleProgressChange}
											styles={`
										& {
											/* removing default appearance */
											-webkit-appearance: none;
											appearance: none; 
											/* creating a custom design */
											width: 100%;
											cursor: pointer;
											outline: none;
											/*  slider progress trick  */
											overflow: hidden;
											border-radius: 16px;
										}
										
										/* Track: webkit browsers */
										&::-webkit-slider-runnable-track {
											height: 5px;
											background: #000;
											border-radius: 16px;
										}
										
										/* Track: Mozilla Firefox */
										&::-moz-range-track {
											height: 5px;
											background: #ccc;
											border-radius: 16px;
										}
										
										/* Thumb: webkit */
										&::-webkit-slider-thumb {
											/* removing default appearance */
											-webkit-appearance: none;
											appearance: none; 
											/* creating a custom design */
											height: 8px;
											width: 8px;
											background-color: #fff;
											border-radius: 50%;
											border: 2px solid #fff;
											/*  slider progress trick  */
											box-shadow: -407px 0 0 400px #fff;
										}
										
										
										/* Thumb: Firefox */
										&::-moz-range-thumb {
											height: 5px;
											width: 5px;
											background-color: #fff;
											border-radius: 50%;
											border: 1px solid #f50;
											/*  slider progress trick  */
											box-shadow: -407px 0 0 400px #f50;
										}						
										`}
										/>
									</Box>
									<Box row spacebetween>
										<Box row gap12 sharedProps={{ size: "2em", fill: "#fff" }}>
											{isPlaying ?
												<Icon path={Icons.pause} onClick={togglePlayPause} />
												:
												<Icon path={Icons.play} onClick={togglePlayPause} />
											}
											<Icon path={Icons.left} onClick={skipBackward} />
											<Icon path={Icons.right} onClick={skipForward} />
											<Icon path={Icons.volume} />
											<Box height="15px" width="100px" place="center">
												<input
													type="range"
													min="0"
													max="100"
													value={volume * 100}
													onChange={handleVolumeChange}
												/>
											</Box>
											<Box white padding15>
												{formatTime(currentTime)} / {formatTime(duration)}
											</Box>
										</Box>
										<Box row gap12 sharedProps={{ size: "2em", fill: "#fff" }}>
											{isFullScreen ?
												<Icon path={Icons.compress} onClick={toggleFullScreen} />
												:
												<Icon path={Icons.expand} onClick={toggleFullScreen} />
											}
											<Icon path={Icons.caption} onClick={toggleSubtitles} />
											<Icon path={Icons.gear} onClick={() => setIsSettings(!isSettings)} />
										</Box>
									</Box>
								</Box>
							</Box>
							)}
					</Box>
				}

				<Box
					absolute
					fullw
					height="100vh"
					placecontent="center"
					background={`linear-gradient(to right, #0b0d0e, #000000a3)`}
					visibility={!isPlaying ? 'visible' : 'hidden'}
				>
					<Box
						padding="0 5%"
						maxwidth="1200px"
						animation="cubic-bezier(.4,0,.2,1) 1s a"
						styles={`
							@keyframes a {
								0% {
									opacity: 0;
									transform: translate3d(0,20%,0);
								}
								
								100% {
									opacity: 1;
									transform: translateZ(0);
								}
							}
						`}
						white
						gap6
					>
						<Box h1 font8>Emprende el viaje hacia la excelencia en ingeniería</Box>
						<Box h2 font5>Explora, innova y lidera con el Departamento de Ingeniería Industrial de la Universidad de Concepción</Box>
					</Box>
				</Box>
				{!isMobile &&
					<Box absolute row gap10 right="50px" bottom="50px">
						<Icon path={Icons.expand} onClick={toggleFullScreen} fill="#fff" size="2em" center />
						<Button onClick={togglePlayPause} background="black" white>
							{isPlaying ?
								"Parar"
								:
								"Iniciar"
							} video
						</Button>
					</Box>
				}
				<Box absolute bottom="50px" right="50%">
					<Icon path={Icons.down} fill="#fff" active={!scrolling} size={useIsMobileCond("2em", "5em")} />
				</Box>
			</Box>
		</>
	)
}