import React, { ReactNode, useState, useEffect, useContext, createContext } from 'react';

export type ConfigProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export type ConfigData = {
	appName: string;
	url: string;
};

const ConfigContext = createContext(null);

export function ConfigProvider({ children }: ConfigProps) {
	const [config, setConfig] = useState<ConfigData | null>(null);

	useEffect(() => {
		// Asynchronous function to fetch the JSON file
		async function fetchConfig() {
			try {
				const response = await fetch("/config.json"); // Make sure the path is correct
				if (!response.ok) {
					throw new Error("Failed to load the configuration file");
				}
				const data = await response.json();

				// Check the value of env and adjust the URL accordingly
				if (data.env === "dev") {
					data.url = data.devUrl;
				}

				setConfig(data);
			} catch (error) {
				console.error(error);
			}
		}

		fetchConfig();
	}, []);

	return (
		<ConfigContext.Provider value={config}>
			{children}
		</ConfigContext.Provider>
	);
}

export function useConfig(): ConfigData | null {
	return useContext(ConfigContext);
}