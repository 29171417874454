import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export type ProtectedRouteProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	allowed?: ReactNode;

	notAllowed?: ReactNode;

	isAllowed?: boolean;

	redirect?: string;
};

export function ProtectedRoute({
	allowed,
	notAllowed,
	isAllowed,
	redirect,
}: ProtectedRouteProps) {
	if (isAllowed) {
		return <>{allowed}</>;
	} else if (redirect) {
		return <Navigate to={redirect} />;
	} else if (notAllowed) {
		return <>{notAllowed}</>;
	} else {
		// Redirect to a default route or render an appropriate message
		return <Navigate to="/" />;
	}
}
