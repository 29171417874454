import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Console as ConsoleComponent } from '@/ui/console';

export type ConsoleProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function Console({ children }: ConsoleProps) {
	return (
		<Box>
			<ConsoleComponent />
		</Box>
	);
}
