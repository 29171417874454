import { Box } from '@/common/layout/box';
import { Image } from '@/common/content/image';
import React, { ReactNode } from 'react';
import NotFoundImg from './images/not_found.png';
import { Link } from '@/common/navigation/link';

export type NotFoundProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function NotFound({ children }: NotFoundProps) {
	return (
		<Box gap="10px" center fullh padding6 gap6>
			<Image src={NotFoundImg} alt="Frank looking" />
			<Box h1>¡Oh, no! Página no encontrada.</Box>
			<Box p>No hemos encuentrado la página que estás buscando. Por favor comprueba la URL o inténtalo de nuevo desde la <Link href="/">página de inicio.</Link></Box>
		</Box>
	);
}
