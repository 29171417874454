import { Box } from '@/common/layout/box';
import { Login as LoginComponent } from '@/ui/login';
import React, { ReactNode } from 'react';

export type LoginProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function Login({ children }: LoginProps) {
	return (
		<Box place="center" margin="45px auto">
			<LoginComponent route="/login" />
		</Box>
	);
}
