import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Image } from '@/common/content/image';
import Udec from './images/udec.png';
import { useIsMobileCond } from '@/hooks/use-is-mobile-cond';
import { Link } from '@/common/navigation/link';

export type AcademicProgramsProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function AcademicPrograms({ children }: AcademicProgramsProps) {

	return (
		<Box article textjustify>
			<Box background="#0e3f6a" padding="2rem 2%" gap10 white>
				<Box h1>
					Programas Académicos
				</Box>
				<Box h2 font9>
					Bienvenidos a la sección de Programas Académicos del Departamento de Ingeniería Industrial, donde la teoría se encuentra con la práctica para moldear a los ingenieros del mañana. Descubre nuestros programas de pregrado, postgrado y doctorado, cada uno diseñado para ofrecer una educación completa, desafiante y alineada con las demandas del mundo industrial y empresarial.
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Pregrado
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box h3>
					Ingeniería Civil Industrial
				</Box>
				<Box p>
					El programa de Ingeniería Civil Industrial de la Universidad de Concepción se distingue por su enfoque interdisciplinario, que entrelaza áreas clave como la Computación e Informática, Investigación de Operaciones y Ciencias de la Ingeniería, proporcionando una base sólida y versátil para afrontar los retos multifacéticos del mundo industrial. Esta base robusta se complementa con una formación avanzada en Ingeniería Económica, Gestión y Producción, preparando a los futuros profesionales para liderar y optimizar procesos dentro de un amplio rango de sectores industriales y de sistemas, sin perder de vista la importancia de la sostenibilidad y la gestión ambiental.
				</Box>
				<Box p>
					El currículo está diseñado para fomentar una comprensión profunda de los principios técnico-económicos que rigen la gestión empresarial y la toma de decisiones estratégicas, asegurando que nuestros egresados no solo sean competentes en la aplicación de tecnologías avanzadas, sino también capaces de asumir roles de liderazgo y ser agentes de cambio innovadores. La flexibilidad del programa permite a los estudiantes personalizar su educación, profundizando en especializaciones que alinean con sus intereses y las demandas cambiantes del sector industrial.
				</Box>
				<Box p>
					Además de la teoría, el programa enfatiza la aplicación práctica del conocimiento a través de laboratorios, proyectos de campo y colaboraciones con la industria, garantizando que nuestros ingenieros civiles industriales estén preparados para enfrentar los desafíos contemporáneos y contribuir significativamente al crecimiento y la mejora continua de las organizaciones en las que se desempeñen.
				</Box>
				<Box>
					<Box gap10>
						<Box p><Box bold>Descripción: </Box>El programa de Ingeniería Civil Industrial ofrece una formación integral que abarca los fundamentos técnicos, económicos y de gestión necesarios para sobresalir en la industria moderna.
						</Box>
						<Box p>
							<Box bold>Duración: </Box>5.5 años (11 semestres), incluyendo prácticas profesionales y un proyecto de titulación.
						</Box>
						<Box p>
							<Box bold>Plan de estudios: </Box>Asignaturas de ciencias básicas, fundamentos de ingeniería, especialización y electivos en Ingeniería Industrial.
						</Box>
						<Box p>
							<Box bold>Salidas profesionales: </Box>Nuestros egresados se destacan en u diverso campo ocupacional que se pueden desempeñar incluyendo sectores como manufactura, logística, consultoría y tecnologías de la información.
						</Box>
					</Box>
				</Box>
				<Box white radius12 overflow="hidden">
					<Box h3 background="#0e3f6a" padding="2rem 2%">
						Malla Curricular
					</Box>
					<Box row={useIsMobileCond()}>
						<Box background="#0e3f6a" padding="2rem 2%">
							<Image src={Udec} />
						</Box>
						<Box h4 background="orange" padding="2rem 2%" font5 fullw>
							Ingeniería Civil Industrial
						</Box>
					</Box>
					<Box overflow="auto">
						<Box
							row
							gray
							sharedProps={{
								border: "1px solid #80808029",
								width: "100%",
								textcenter: true,
								padding: "2rem",
							}}
						>
							<Box>1º Año</Box>
							<Box>2º Año</Box>
							<Box>3º Año</Box>
							<Box>4º Año</Box>
							<Box>5º Año</Box>
							<Box>6º Año</Box>
						</Box>
						<Box
							row
							white
							sharedProps={{
								row: true,
								border: "1px solid #80808029",
								width: "100%",
								textcenter: true,
								sharedProps: {
									sharedProps: {
										background: "#2d3d63",
										border: "1px solid #80808029",
										height: "110px",
										contentcenter: true,
										shover: `background: #304371;`
									}
								}
							}}
						>
							<Box>
								<Box>
									<Box>Física I</Box>
									<Box>Álgebra I</Box>
									<Box>Cálculo I</Box>
									<Box>Química General I</Box>
									<Box>Desarrollo de Habilidades de Gestión</Box>
								</Box>
								<Box>
									<Box>Física II</Box>
									<Box>Álgebra II</Box>
									<Box>Cálculo II</Box>
									<Box>Química General II</Box>
									<Box>Introducción a la Innovación en Ingeniería</Box>
								</Box>
							</Box>
							<Box>
								<Box>
									<Box>Cálculo III</Box>
									<Box>Ecuaciones Diferenciales</Box>
									<Box>Programación</Box>
									<Box>Estadística</Box>
									<Box>Liderazgo y Trabajo en Equipo</Box>
									<Box>Inglés Comunicativo Nivel Básico I</Box>
								</Box>
								<Box>
									<Box>Inferencia Estadística y Muestreo</Box>
									<Box>Cálculo Numérico</Box>
									<Box>Mecánica</Box>
									<Box>Termodinámica</Box>
									<Box>Modelación de Sistemas</Box>
									<Box>Inglés Comunicativo Nivel Básico II</Box>
								</Box>
							</Box>
							<Box>
								<Box>
									<Box>Mecánica de Fluidos</Box>
									<Box>Máquinas Eléctricas</Box>
									<Box>Microeconomía</Box>
									<Box>Análisis Estadístico Multivariado</Box>
									<Box>Optimización I</Box>
									<Box>Práctica Laboral (*)</Box>
								</Box>
								<Box>
									<Box>Transferencia de Calor</Box>
									<Box>Administración</Box>
									<Box>Macroeconomía</Box>
									<Box>Simulación</Box>
									<Box>Optimización II</Box>
									<Box>Dibujo Industrial</Box>
								</Box>
							</Box>
							<Box>
								<Box>
									<Box>Procesos Industriales</Box>
									<Box>Innovación</Box>
									<Box>Marketing</Box>
									<Box>Contabilidad General y de Costos</Box>
									<Box>Gestión Estratégica y Control de Gestión</Box>
									<Box>Introducción a la Sustentabilidad en Ingeniería</Box>
									<Box>Complementaria I</Box>
								</Box>
								<Box>
									<Box>Planificación y Control de Producción</Box>
									<Box>Ingeniería Económica</Box>
									<Box>Finanzas</Box>
									<Box>Gestión y Control de Calidad</Box>
									<Box>Tecnologías de Información</Box>
									<Box>Complementaria II</Box>
								</Box>
							</Box>
							<Box>
								<Box>
									<Box>Diseño de Sistemas de Producción</Box>
									<Box>Evaluación de Proyectos</Box>
									<Box>Gestión de Personas y Comportamiento Organizacional</Box>
									<Box>Práctica Profesional (*)</Box>
									<Box>Electivo I</Box>
									<Box>Electivo II</Box>
									<Box>Electivo III</Box>
								</Box>
								<Box>
									<Box>Dirección y Control de Proyectos</Box>
									<Box>Taller de Emprendimiento</Box>
									<Box>Logística</Box>
									<Box>Electivo IV</Box>
									<Box>Electivo V</Box>
									<Box>Electivo VI</Box>
								</Box>
							</Box>
							<Box>
								<Box>
									<Box>Memoria de Título</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box white radius12 overflow="hidden" gap6>
					<Box>
						<Box h3 background="#0e3f6a" padding="2rem 2%">
							Detalles
						</Box>
						<Box row sharedProps={{center: true, padding: "2rem 0rem", fullh: true}}>
							<Box basis="20%" background="#009ada">NEM 20%</Box>
							<Box basis="15%" background="#93bee6">Ranking 15%</Box>
							<Box basis="15%" background="#d69731">Comprensión Lectora 15%</Box>
							<Box basis="25%" background="#ca6005">Matemáticas I 25%</Box>
							<Box basis="15%" background="#b9017c">Matemáticas II 15%</Box>
							<Box basis="10%" background="#8b007e">Ciencias 10%</Box>
						</Box>
					</Box>
					<Box black padding10 wrap gap6 spacebetween>
						<Box gap6>
							<Box h3>Programa</Box>
							<Box gap12>
								<Box p><Box bold>Grado Académico: </Box>Licenciado/a en Ciencias de la Ingeniería</Box>
								<Box p><Box bold>Título Profesional: </Box>Ingeniero/a Civil Industrial</Box>
								<Box p><Box bold>Acreditación: </Box>4 años</Box>
							</Box>
						</Box>
						<Box gap6>
							<Box h3>Puntaje</Box>
							<Box gap12>
								<Box p><Box bold>Código Demre: </Box>13072</Box>
								<Box p><Box bold>Vacantes: </Box>113</Box>
								<Box p><Box bold>Puntaje de Corte: </Box>760,35</Box>
								<Box p><Box bold>Puntaje Pond. Mínimo de Postulación: </Box>500</Box>
								<Box p><Box bold>Puntaje Prom. C y M mínimo de Post.: </Box>485</Box>
								<Box p><Box bold>Duración: </Box>11 semestres</Box>
							</Box>
						</Box>
						<Box gap6>
							<Box h3>Arancel</Box>
							<Box gap12>
								<Box p><Box bold>Monto: </Box>$ 6.322.735</Box>
								<Box p><Box bold>Beca Interna UdeC (*): </Box>13072</Box>
								<Box p><Box bold>Vacantes: </Box>$ 1.148.587</Box>
								<Box p><Box bold>Arancel menos Beca: </Box>$ 5.174.148</Box>
								<Box p><Box bold>Valor Matrícula: </Box>$ 160.000</Box>
							</Box>
						</Box>
						<Box gap6>
							<Box h3>Duración y Empleabilidad (SIES)</Box>
							<Box gap12>
								<Box p><Box bold>Duración Real (semestres): </Box>$ 6.322.735</Box>
								<Box p><Box bold>Beca Interna UdeC (*): </Box>13,7</Box>
								<Box p><Box bold>Empleabilidad al 1° año: </Box>81,2%</Box>
								<Box p><Box bold>Empleabilidad al 2° año: </Box>$ 92,9%</Box>
							</Box>
						</Box>
					</Box>
					<Box p background="#0e3f6a" padding="2rem 2%" white>
						Esta información es referencial y puede variar de acuerdo a las políticas de la Universidad de Concepción. Para mayor información, visite la página de Admisión UdeC. <Link href="https://admision.udec.cl/ingenieria-civil-industrial/" underline>Ir a admisión</Link>
					</Box>
				</Box>
			</Box>
			<Box h3 background="orange" padding="2rem 2%" white>
				Postgrado
			</Box>
			<Box h3 background="#0e3f6a" padding="2rem 2%" white>
				Magíster
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box h3>
					Magíster en Ingeniería Industrial
				</Box>
				<Box p>
					Dirigido a profesionales que buscan profundizar en áreas específicas de la ingeniería industrial, este programa potencia habilidades en gestión y análisis de sistemas complejos.
				</Box>
				<Box>
					<Box gap10>
						<Box p>
							<Box bold>Duración: </Box>2 años, opción a tesis o proyecto final de investigación.
						</Box>
						<Box p>
							<Box bold>Áreas de especialización: </Box>Optimización, logística, gestión de la calidad, y más.
						</Box>
					</Box>
				</Box>
				<Link href="https://postgrado.udec.cl/programas/programa/magister/4170" underline>Leer más</Link>
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box h3>
					Magíster en Innovación y Emprendimiento Tecnológico
				</Box>
				<Box p>
					Un programa único enfocado en la intersección de la ingeniería, la tecnología y los negocios, preparando a los estudiantes para lanzar y gestionar proyectos innovadores.
				</Box>
				<Box>
					<Box gap10>
						<Box p>
							<Box bold>Duración: </Box>2 años, con opción a tesis o proyecto final.
						</Box>
						<Box p>
							<Box bold>Áreas de especialización: </Box>Optimización, logística, gestión de la calidad, y más.
						</Box>
					</Box>
				</Box>
				<Link href="https://postgrado.udec.cl/programas/programa/magister/4317" underline>Leer más</Link>
			</Box>
			<Box h3 background="#0e3f6a" padding="2rem 2%" white>
				Doctorado
			</Box>
			<Box padding="2rem 2%" gap6>
				<Box h3>
					Doctorado en Ingeniería Industrial
				</Box>
				<Box p>
					Ofrece una formación avanzada en investigación, preparando a los estudiantes para contribuir al conocimiento en ingeniería industrial y para liderar en academia o la industria.
				</Box>
				<Box>
					<Box gap10>
						<Box p>
							<Box bold>Duración: </Box>4 años, incluyendo cursos avanzados, investigación y una tesis doctoral.
						</Box>
						<Box p>
							<Box bold>Líneas de investigación: </Box>Sistemas de producción, gestión de operaciones, innovación tecnológica, y más.
						</Box>
					</Box>
				</Box>
				<Link href="https://postgrado.udec.cl/programas/programa/doctorado/4242" underline>Leer más</Link>
			</Box>
			{children}
		</Box>
	);
}