import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Box } from '@/common/layout/box';
import { useData } from '@/context/data';
import { Logo } from '@/ui/logo';
import { Theme } from '@/common/themes/theme-provider';
import { Outlet } from 'react-router-dom';

export type StoreAppProps = {
    /**
     * a node to be rendered in the special component.
     */
    children?: ReactNode;
};

export function StoreApp({ children }: StoreAppProps) {

    return (
        <Theme>
            <Outlet />
        </Theme>
    );
}