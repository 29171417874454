import React, { ReactNode } from 'react';
import { useMergeState } from '@/hooks/use-merge-state';
import { useParams } from 'react-router-dom';
import { Box } from '@/common/layout/box';
import { useAxios } from '@/hooks/use-axios';
import { Image } from '@/common/content/image';
import Banner from './images/banner.png';
import Online from './images/online.gif';
import Offline from './images/offline.gif';
import Dialogue from './images/dialogue.gif';
import styled from 'styled-components';

import Credit from './images/wallet.png';
import Diamond from './images/diamond.png';
import Ducket from './images/ducket.png';
import Cuack from './images/cuack.png';

export type ProfileProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function Profile({ children }: ProfileProps) {
	const [data, setData] = useMergeState({});
	let { user } = useParams();

	const User = useAxios({
		url: '/user/get/' + user,
		onSuccess: setData
	}, true);

	return (
		<CardIndex fontfamily={`"Ubuntu Condensed","Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Tahoma,sans-serif`} gap="10px">
			<Box row placeitems="center" gap="15px">
				<h2>{data.user?.username}</h2>
				<Image src={data.user?.online ? Online : Offline} alt={`Estado de ${data.user?.username}`} />
			</Box>
			<Box>
				<Box row
					background="#383838a6"
					placeitems="center"
					padding="10px"
					radius="10px"
					color="white"
					fontsize="medium"
					fontweight={400}
					gap="15px"
				>
					<Image src={Dialogue} alt={`Misión de ${data.user?.username}`} />
					<p>{data.user?.motto}</p>
				</Box>
				<Box wrap>
					<Image src={`/imaging/avatar/${data.user?.username}`} alt={`Avatar de ${data.user?.username}`} />
					<Box margin="auto 10px" gap="5px">
						<Box row gap="15px"><Image src={Credit} alt="Créditos" />Credits: {data.user?.currency.credits}</Box>
						<Box row gap="15px"><Image src={Diamond} alt="Diamantes" />Diamantes: {data.user?.currency.diamonds}</Box>
						<Box row gap="15px"><Image src={Ducket} alt="Duckets" />Duckets: {data.user?.currency.duckets}</Box>
						<Box row gap="15px"><Image src={Cuack} alt="Cuacks" />Cuacks: {data.user?.currency.cuacks}</Box>
					</Box>
				</Box>
			</Box>
		</CardIndex>
	);
}

export const CardIndex = styled(Box)`
& {
	background: url(${Banner});
	background-position: center;
    background-size: cover;
	color: white;
}
`;