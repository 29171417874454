import React, { ReactNode } from 'react';
import { BigButton } from '@/ui/button';
import { Box } from '@/common/layout/box';
import { Icon } from '@/common/fonts/icon';
import { Image } from '@/common/content/image';
import { Link } from '@/common/navigation/link';
import { useAxios } from '@/hooks/use-axios';
import { useMergeState } from '@/hooks/use-merge-state';
import { useIsMobileCond } from '@/hooks/use-is-mobile-cond';
import Landing from './images/landing.jpeg';
import Landing1 from './images/landing1.jpg';
import Landing2 from './images/landing2.png';
import Icon1 from './images/icon1.png';
import Icon2 from './images/icon2.png';
import Icon3 from './images/icon3.png';
import Icon4 from './images/icon4.png';
import Icon5 from './images/icon5.png';
import parse, { domToReact } from 'html-react-parser';

export type HomeProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;
};

const Icons = {
	check: "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
}

const htmlString = `
  <Box row gap1>
    <Box>
      Nos complace invitar a los estudiantes de Ingeniería Civil Industrial...
    </Box>
    <Box>
      Para más información y registro, por favor contacta a la académica Rosa Medina.
    </Box>
  </Box>
`;

const replace = domNode => {
	if (domNode.type === 'tag' && domNode.name === 'box') {
		const props = Object.keys(domNode.attribs).reduce((acc, key) => {
			// Si el atributo está presente pero no tiene valor, asumimos que es true
			const value = domNode.attribs[key];
			acc[key === 'class' ? 'className' : key] = value === "" ? true : value;
			return acc;
		}, {});

		const children = domToReact(domNode.children, { replace }); // Procesamiento recursivo
		return <Box {...props}>{children}</Box>;
	}
};

const MyComponent = () => {
	const jsxElements = parse(htmlString, { replace });

	return <>{jsxElements}</>;
};

export function Home({ children }: HomeProps) {
	const [data, setData] = useMergeState({});

	useAxios({
		url: '/news/list',
		onSuccess: setData,
		values: {
			limit: 3
		}
	}, true);

	const urlRegex = /\s/g;

	return (
		<Box article>
			<Box section padding="2rem 5%" background="#000729" gap6 white>
				<Box h2 uppercase>Últimas noticias</Box>
				<Box gap="10px" autocolumns={useIsMobileCond()} height="300px">
					{data.result && Object.keys(data.result).map((key, i) => (
						<Box
							key={i}
							background={`linear-gradient(
							rgba(0, 0, 0, 0.5),
							rgba(0, 0, 0, 0.5)
							), url(${data.result[key].image})`}
							backgroundcover
						>
							<Box gap10 padding6>
								<Link href={`/article/${data.result[key].title.toLowerCase().replace(urlRegex, '-')}`}>
									<Box h3>{data.result[key].title}</Box>
								</Link>
								<Box p4>{data.result[key].shortstory}</Box>
							</Box>
						</Box>
					))}
				</Box>
			</Box>
			<Box section padding="4rem 5%" autocolumns={useIsMobileCond()} gap6>
				<Box>
					<Box overflow="hidden">
						<Image src={Landing} height="300px" fitw />
						<Image
							src={Landing1}
							border="10px solid white"
							height="350px"
							top="-100px"
							left="100px"
							relative
						/>
					</Box>
					<Box relative>
						<Box top="-100px" left="0px" color="#01416f" bold font5 absolute>+</Box>
						<Box top="-20px" left="150px" color="#01416f" bold font5 absolute>+</Box>
						<Box top="-150px" left="150px" color="#ffffff" bold font6 absolute>+</Box>
					</Box>
				</Box>
				<Box gap6 padding="2rem 0" textjustify>
					<Box gap6>
						<Box gap9>
							<Box h2 color="#01416f">
								Conoce el Departamento de Ingeniería Industrial
							</Box>
							<Box p4>
								Tradición e Innovación al Servicio de la Ingeniería del Futuro
							</Box>
						</Box>
						<Box>
							Desde su fundación en 1993, el Departamento de Ingeniería Industrial de la Universidad de Concepción ha sido un faro de excelencia académica y un motor de innovación. Nuestro compromiso es formar líderes capaces de diseñar soluciones ingeniosas para los complejos desafíos de la industria y la sociedad.
						</Box>
					</Box>
					<Box gap10>
						<Box row gap10>
							<Icon path={Icons.check} fill="#01416f" size="1.5em" center />
							<Box h4 color="#01416f" bold center>Historia y Legado</Box>
						</Box>
						<Box>Con una trayectoria que refleja la evolución de la ingeniería industrial, el DII ha crecido sobre los pilares de la calidad educativa y la investigación avanzada.</Box>
					</Box>
					<Box gap10>
						<Box row gap10>
							<Icon path={Icons.check} fill="#01416f" size="1.5em" center />
							<Box h4 color="#01416f" bold center>Misión Educativa</Box>
						</Box>
						<Box>Nos dedicamos a impartir una educación que equipa a nuestros estudiantes con conocimientos técnicos, visión estratégica y una profunda conciencia social.</Box>
					</Box>
					<Box gap10>
						<Box row gap10>
							<Icon path={Icons.check} fill="#01416f" size="1.5em" center />
							<Box h4 color="#01416f" bold center>Innovación y Emprendimiento</Box>
						</Box>
						<Box>El DII es un ecosistema donde la creatividad y el espíritu emprendedor prosperan, impulsando el desarrollo de tecnologías y prácticas vanguardistas.</Box>
					</Box>
					<Box gap10>
						<Box row gap10>
							<Icon path={Icons.check} fill="#01416f" size="1.5em" center />
							<Box h4 color="#01416f" bold center>Compromiso con la Sociedad</Box>
						</Box>
						<Box>Creemos en la responsabilidad de la ingeniería industrial para contribuir al desarrollo sostenible y al bienestar de la comunidad global.</Box>
					</Box>
				</Box>
			</Box>
			<Box section padding="4rem 5%" background="#326ea1" white gap3>
				<Box h2 font6 center>
					Mensaje de Nuestro Director
				</Box>
				<Box center>
					<Box autocolumns={useIsMobileCond()} gap6>
						<Box gap6 padding="2rem 0" textjustify>
							<Box h3>
								Innovación y Liderazgo en Ingeniería Industrial
							</Box>
							<Box>
								En la confluencia de la tradición y el futuro, el Departamento de Ingeniería Industrial de la Universidad de Concepción se erige como un pilar de excelencia. Aquí, en nuestro prestigioso departamento, no solo forjamos la próxima generación de ingenieros industriales; cultivamos visionarios y pioneros listos para abordar los desafíos que plantea nuestro mundo en constante cambio.
							</Box>
							<Box>
								Cada día, en nuestras aulas y laboratorios, se respira el espíritu de innovación. Creemos firmemente que la ingeniería es mucho más que fórmulas y cálculos; es un llamado a la acción, un impulso para mejorar, una misión para liderar. Nuestros programas están diseñados para ir más allá de la teoría, para sumergir a nuestros estudiantes en la práctica real y en la multidisciplinariedad que los tiempos actuales demandan.
							</Box>
							<Box>
								En colaboración con industrias líderes y con una visión sostenible, nuestros profesores y estudiantes trabajan mano a mano para desarrollar soluciones tecnológicas responsables. Estamos comprometidos con la creación de un futuro donde la eficiencia y la innovación caminen de la mano con la ética y la responsabilidad social.
							</Box>
							<Box>
								Invito a cada uno de ustedes a sumergirse en nuestra comunidad. A descubrir y desarrollar su potencial. A unirse a nosotros en esta aventura de conocimiento y crecimiento. Juntos, podemos marcar la diferencia y contribuir a un mundo más inteligente, más sostenible y más justo.
							</Box>
							<Box>
								El futuro nos llama, y en el Departamento de Ingeniería Industrial, estamos listos para liderar el camino.
							</Box>
							<Box>
								— Jorge Jiménez
							</Box>
						</Box>
						<Image src={Landing2}></Image>
					</Box>
				</Box>
				<Box relative white>
					<Box top="-100px" left="0px" bold font5 absolute>+</Box>
					<Box top="-100px" right="0px" bold font5 absolute>+</Box>
					<Box top="-20px" right="150px" bold font5 absolute>+</Box>
				</Box>
			</Box>
			<Box section padding="4rem 5%" background="#252a60" white gap3>
				<Box gap10>
					<Box h2 font6 center>
						Nuestros Resultados
					</Box>
					<Box h3 font7 center>
						Compromiso con la Excelencia y el Futuro
					</Box>
				</Box>
				<Box
					wraps
					center
					gap6
					textcenter
					sharedProps={{ width: "300px", center: true, gap6: true }}
				>
					<Box>
						<Image src={Icon1} size="128px" background="white" padding10 radius5 />
						<Box p>Institución distinguida por más de <Box bold>7 años de acreditación</Box>, garantizando la excelencia educativa.</Box>
					</Box>
					<Box>
						<Image src={Icon2} size="128px" background="white" padding10 radius5 />
						<Box p>Orgullosos de más de <Box bold>1.500 egresados</Box> que lideran y destacan en la industria a nivel global.</Box>
					</Box>
					<Box>
						<Image src={Icon3} size="128px" background="white" padding10 radius5 />
						<Box p>El <Box bold>90% de nuestros docentes con doctorados</Box>, comprometidos con la enseñanza de calidad.</Box>
					</Box>
					<Box>
						<Image src={Icon4} size="128px" background="white" padding10 radius5 />
						<Box p>Alianzas con universidades de <Box bold>prestigio internacional</Box>, ofreciendo amplias oportunidades de movilidad.</Box>
					</Box>
					<Box>
						<Image src={Icon5} size="128px" background="white" padding10 radius5 />
						<Box p>Graduados con <Box bold>alta empleabilidad</Box>, preparados para integrarse en las industrias líderes.</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
