import React, { ReactNode } from 'react';
import { useData } from '@/context/data';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@/common/navigation/protected-route';
import { StoreApp } from '@/app/layouts/store-app';
import { StoreFront } from '@/app/layouts/store-front';
import { StoreHome } from '@/app/layouts/store-home';
import { StoreRegister } from '@/app/layouts/store-register';
import { StoreAdmin } from '@/app/layouts/storeadmin';
import { NotFound } from '@/app/page/not-found';
import { Home } from '@/app/page/home';
import { Me } from '@/app/page/me';
import { Login } from '@/app/page/login';
import { Logout } from '@/app/page/logout';
import { Register } from '@/app/page/register';
import { TermsOfService } from '@/app/page/terms-of-service';
import { News } from '@/app/page/news';
import { Article } from '@/app/page/article';
import { Profile } from '@/app/page/user/profile';
import { Dashboard } from '@/app/page/admin/dashboard';
import { Console } from '@/app/page/admin/console';
import { Registry } from '@/app/page/admin/registry';
import { AboutUs } from '@/app/page/about-us';
import { AcademicPrograms } from '@/app/page/academic-programs';
import { Research } from '@/app/page/research';
import { StudentLife } from '@/app/page/student-life';
import { ContactUs } from '@/app/page/contact-us';

export type RoutesProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function Router({ children }: RoutesProps) {
	const { data, fetch, setData } = useData()

	if (!data.token) return;

	return (
			<BrowserRouter>
				<Routes>
					<Route element={<StoreApp />}>
						<Route element={
								<ProtectedRoute
									allowed={<StoreFront />}
									notAllowed={<StoreHome />}
									isAllowed={data.loggedIn}
								/>}>
							<Route path="/" element={
								<ProtectedRoute
									allowed={<Me />}
									notAllowed={<Home />}
									isAllowed={data.loggedIn}
								/>}/>
						</Route>
						<Route element={<StoreFront />}>
							<Route path="/me" element={<Me />} />
							<Route path="/logout" element={<Logout />} />
							<Route path="/terms-and-service" element={<TermsOfService />} />
							<Route path="/news" element={<News />} />
							<Route path="/article/:article" element={<Article />} />
							<Route path="/profile/:user" element={<Profile />} />
							<Route path="/about-us" element={<AboutUs />} />
							<Route path="/academic-programs" element={<AcademicPrograms />} />
							<Route path="/research" element={<Research />} />
							<Route path="/student-life" element={<StudentLife />} />
							<Route path="/contact-us" element={<ContactUs />} />
							<Route path="*" element={<NotFound />} />
						</Route>
						<Route element={<StoreRegister />}>
							<Route path="/register" element={<Register />} />
						</Route>
						<Route path="/admin" element={
								<ProtectedRoute
								allowed={
									<StoreAdmin />
								}
								notAllowed={<Login />}
								isAllowed={data.user?.role > 21}
							/>}>
							<Route index element={<Dashboard />} />
							<Route path="console" element={<Console />} />
							<Route path="registry" element={<Registry />} />
						</Route>
					</Route>
				</Routes>
			</BrowserRouter>
	);
}
