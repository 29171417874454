import React, { ReactNode } from 'react';
import { useIsMobile } from '@/hooks/use-is-mobile';

/**
 * A custom hook to conditionally set values based on the `isMobile` status.
 * @param {*} whenTrue The value to use when `isMobile` is true.
 * @param {*} whenFalse The value to use when `isMobile` is false.
 * @returns {*} The value corresponding to the `isMobile` status.
 */

export function useIsMobileCond(whenTrue: any = false, whenFalse: any = whenTrue ? false : true) {
	
	const isMobile = useIsMobile();
	return isMobile ? whenTrue : whenFalse;

}
