import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Link } from '@/common/navigation/link';
import { Nav, List } from '@/common/navigation/nav';
import styled from 'styled-components';
import { useData } from '@/context/data';

export type AdminMenuProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function AdminMenu({ children }: AdminMenuProps) {
	const { data } = useData()
	
	return (
		<Nav as={NavStyle} background="#fff" margin="0 0 32px 0" padding="0 32px" gap="1rem">
			<List gap="10px">
				<Link href="/admin">Inicio</Link>
				<Link href="/admin/registry">Registros</Link>
				<Link href="/admin/calendar">Calendario</Link>
			</List>
			<Box as="h3" color="#335a81" fontsize="1rem">Sitio</Box>
			<List gap="10px">
				<Link href="/admin/backups">Backups</Link>
				<Link href="/admin/news">Noticias</Link>
			</List>

			<Box as="h3" color="#335a81" fontsize="1rem">Usuarios</Box>
			<List gap="10px">
				<Link href="/admin/users">Usuarios</Link>
			</List>

			<Box as="h3" color="#335a81" fontsize="1rem">Administración</Box>
			<List gap="10px">
				<Link href="/admin/general">General</Link>
				<Link href="/admin/pages">Páginas</Link>
				<Link href="/admin/roles">Roles</Link>
				<Link href="/admin/console">Consola</Link>
				{
					data.user?.role > 21 &&
					<Link href="/admin">Administración</Link>
				}
				{children}
			</List>
		</Nav>
	);
}

export const NavStyle = styled.nav`
& {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
& > ul > li > a  {
	padding: 10px 15px;
}
`;