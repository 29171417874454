import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Outlet } from 'react-router-dom';
import { AdminMenu } from '@/ui/admin-menu';
import { AdminNavbar } from '@/ui/admin-navbar';
import { Footer } from '@/ui/footer';
import styled from 'styled-components';

export type StoreAdminProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function StoreAdmin({ children }: StoreAdminProps) {
	return (
		<Box sbefore={
			`z-index: -1;
			position: absolute;
			background: #153d77;
			content: " ";
			height: 264px;
			width: 100%;`}
			height={100}>
			<AdminNavbar/>
			<Box wrap>
				<AdminMenu />
				<Box col padding="0 1.5rem">
					<Outlet />
				</Box>
			</Box>
			<Footer />
		</Box>
	);
}