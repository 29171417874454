import { useState } from 'react';

export function useMergeState(initialState) {
  const [value, setValue] = useState(initialState);

  const mergeState = (newState) => {
    setValue(prevValue => {
      const mergedState = typeof newState === 'function' ? newState(prevValue) : newState;

      // Iterate over each key in the new state
      for (const key in mergedState) {
        if (mergedState.hasOwnProperty(key)) {
          // Check if both, current and new value for the key, are arrays
          if (Array.isArray(prevValue[key]) && Array.isArray(mergedState[key])) {
            // Concatenate arrays if both values are arrays
            mergedState[key] = [...prevValue[key], ...mergedState[key]];
          }
        }
      }

      // Merge the previous and new states
      return { ...prevValue, ...mergedState };
    });
  };

  return [value, mergeState];
}
