export type Colors = {
    white?: boolean;
    silver?: boolean;
    gray?: boolean;
    black?: boolean;
    red?: boolean;
    maroon?: boolean;
    yellow?: boolean;
    olive?: boolean;
    lime?: boolean;
    green?: boolean;
    aqua?: boolean;
    teal?: boolean;
    blue?: boolean;
    navy?: boolean;
    fuchsia?: boolean;
    purple?: boolean;
    orange?: boolean;
    aliceblue?: boolean;
    antiquewhite?: boolean;
    aquamarine?: boolean;
    azure?: boolean;
    beige?: boolean;
    bisque?: boolean;
    blanchedalmond?: boolean;
    blueviolet?: boolean;
    brown?: boolean;
    burlywood?: boolean;
    cadetblue?: boolean;
    chartreuse?: boolean;
    chocolate?: boolean;
    coral?: boolean;
    cornflowerblue?: boolean;
    cornsilk?: boolean;
    crimson?: boolean;
    cyan?: boolean;
    darkblue?: boolean;
    darkcyan?: boolean;
    darkgoldenrod?: boolean;
};

export const colorKeys: Array<keyof Colors> = [
    "white",
    "silver",
    "gray",
    "black",
    "red",
    "maroon",
    "yellow",
    "olive",
    "lime",
    "green",
    "aqua",
    "teal",
    "blue",
    "navy",
    "fuchsia",
    "purple",
    "orange",
    "aliceblue",
    "antiquewhite",
    "aquamarine",
    "azure",
    "beige",
    "bisque",
    "blanchedalmond",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod"
  ];
  