import React, { ReactNode } from 'react';
import { createGlobalStyle } from 'styled-components';

export type ThemeProviderProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function Theme({ children }: ThemeProviderProps) {
	return (
		<>
			<GlobalStyle />
			{ children }
		</>
	);
}

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

html, body, #root {
    height: 100%;
    width: 100%;
}
`