import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

export type MetaProps = {
    /**
     * A node to be rendered in the special component.
     */
    children?: ReactNode;
};

export function Meta({ children }: MetaProps) {
    
    return (
        <Helmet>
            <html lang="es" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Departamento de Ingeniería Industrial UdeC: Liderando la Innovación y la Excelencia</title>
            <meta name="description" content="Únete al Departamento de Ingeniería Industrial de la Universidad de Concepción y forma parte de una tradición de excelencia. Descubre un enfoque educativo que fusiona la teoría con la práctica y la innovación tecnológica." />
            <meta name="author" content="Departamento de Ingeniería Industrial, Universidad de Concepción" />
            <meta name="keywords" content="Ingeniería Industrial, Universidad de Concepción, Educación Superior, Innovación Tecnológica, Liderazgo en Ingeniería" />
            <link rel="canonical" href="https://www.dii.udec.cl/" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
            {/* Metadatos de redes sociales */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Departamento de Ingeniería Industrial UdeC: Formando Líderes del Mañana" />
            <meta property="og:description" content="El Departamento de Ingeniería Industrial de la Universidad de Concepción es reconocido por su enfoque innovador y su compromiso con la educación de calidad. Conecta con una comunidad que está modelando el futuro de la ingeniería." />
            {/* <meta property="og:image" content="URL-de-tu-imagen-promocional.jpg" /> */}
            <meta property="og:url" content="https://www.dii.udec.cl/" />
            <meta property="og:site_name" content="Departamento de Ingeniería Industrial UdeC" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Explora el Departamento de Ingeniería Industrial de la Universidad de Concepción" />
            <meta name="twitter:description" content="Descubre cómo el Departamento de Ingeniería Industrial de la UdeC combina enseñanza avanzada y aplicación práctica para prepararte para los retos del mañana." />
            {/* <meta name="twitter:image" content="URL-de-tu-imagen-promocional.jpg" /> */}
            <meta name="twitter:site" content="@diiUdeC" />
            {/* Agrega los siguientes si tienes una app */}
            {/* <meta name="apple-itunes-app" content="app-id=tu-app-id" /> */}
            {/* <meta name="google-play-app" content="app-id=tu-app-id" /> */}
            {children}
        </Helmet>
    )
}
