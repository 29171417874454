import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@/common/layout/box';
import styled from 'styled-components';

export type IconProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;

	path?: string;

	size?: string;
} & BoxProps & React.SVGProps<SVGSVGElement>;

export function Icon({ children, path, size="1em", fill, viewBox="-50 -50 680 680", ...props }: IconProps) {

	return (
		<Box {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill={fill}
				viewBox={viewBox}
				preserveAspectRatio="xMidYMid meet"
				textAnchor="middle"
			>
				<path d={path}/>
				{children}
			</svg>
		</Box>
	);
}