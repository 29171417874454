import React, { Children, cloneElement, isValidElement, useMemo, useState } from 'react';
import { BoxProps } from './box-types';
import { ConfigData } from '@/context/config/config';
import { Colors, colorKeys } from './colors';
import { relative } from 'path';

const DEPRECATED_PROPS_MAP = {
    // flexCol: {
    // 	newProp: 'direction',
    // 	newValue: 'column',
    // 	warning: "`flexCol` prop in Box is deprecated. Please use `direction=\"column\"` instead."
    // },
    // flexRow: {
    // 	newProp: 'direction',
    // 	newValue: 'row',
    // 	warning: "`flexRow` prop in Box is deprecated. Please use `direction=\"row\"` instead."
    // }
    // You can extend this object to include any other deprecated properties.
};

const fontSizeMap = {
    h1: '2rem', h2: '1.75rem', h3: '1.5rem', h4: '1.25rem', h5: '1.125rem', h6: '1.125rem',
    p1: '2rem', p2: '1.75rem', p3: '1.5rem', p4: '1.25rem', p5: '1.125rem', p6: '1.125rem', p: '1rem',
};

const gapMap = {
    gap1: '10rem',      // más gaprande
    gap2: '8rem',
    gap3: '6rem',
    gap4: '4rem',
    gap5: '3rem',      // añadido nuevo
    gap6: '2rem',
    gap7: '1.5rem',
    gap8: '1.5625rem', // equivalente a 25px
    gap9: '1.25rem',   // equivalente a 20px
    gap10: '1rem',
    gap11: '0.625rem', // equivalente a 10px
    gap12: '0.5rem',
    gap13: '0.3125rem', // equivalente a 5px, añadido nuevo
    gap14: '0.25rem',
    gap15: '0.125rem',  // más pequeño
};

const radiusMap = {
    radius1: '10rem',      // más radiusrande
    radius2: '8rem',
    radius3: '6rem',
    radius4: '4rem',
    radius5: '3rem',      // añadido nuevo
    radius6: '2rem',
    radius7: '1.5rem',
    radius8: '1.5625rem', // equivalente a 25px
    radius9: '1.25rem',   // equivalente a 20px
    radius10: '1rem',
    radius11: '0.625rem', // equivalente a 10px
    radius12: '0.5rem',
    radius13: '0.3125rem', // equivalente a 5px, añadido nuevo
    radius14: '0.25rem',
    radius15: '0.125rem',  // más pequeño
};

const paddingMap = {
    padding1: '10rem',      // más paddingrande
    padding2: '8rem',
    padding3: '6rem',
    padding4: '4rem',
    padding5: '3rem',      // añadido nuevo
    padding6: '2rem',
    padding7: '1.5rem',
    padding8: '1.5625rem', // equivalente a 25px
    padding9: '1.25rem',   // equivalente a 20px
    padding10: '1rem',
    padding11: '0.625rem', // equivalente a 10px
    padding12: '0.5rem',
    padding13: '0.3125rem', // equivalente a 5px, añadido nuevo
    padding14: '0.25rem',
    padding15: '0.125rem',  // más pequeño
};

const fontMap = {
    font0: '20rem',        // más grande
    font1: '10rem',
    font2: '8rem',
    font3: '6rem',
    font4: '4rem',
    font5: '3rem',        // añadido nuevo
    font6: '2rem',
    font7: '1.5rem',
    font8: '1.5625rem',   // equivalente a 25px
    font9: '1.25rem',     // equivalente a 20px
    font10: '1rem',
    font11: '0.625rem',   // equivalente a 10px
    font12: '0.5rem',
    font13: '0.3125rem',  // equivalente a 5px, añadido nuevo
    font14: '0.25rem',
    font15: '0.125rem',   // más pequeño
};

const paragraphStyles = { bold: 'bold', italic: 'italic', strikethrough: 'line-through', underline: 'underline', overline: 'overline', capitalize: 'capitalize', uppercase: 'uppercase', lowercase: 'lowercase' };
const paragraphTags = { bold: 'strong', italic: 'i', strikethrough: 's', underline: 'u', overline: 'o', capitalize: '', uppercase: '', lowercase: '' };
const styleProps = { bold: 'fontWeight', italic: 'fontStyle', strikethrough: 'textDecoration', underline: 'textDecoration', overline: 'textDecoration', capitalize: 'textTransform', uppercase: 'textTransform', lowercase: 'textTransform' };

export function translateProps(props: BoxProps) {
    const translatedProps = { ...props };

    // if (DEPRECATED_PROPS_MAP) {
    //     for (let oldProp in DEPRECATED_PROPS_MAP) {
    //         if (oldProp in props) {
    //             const { newProp, newValue, warning } = DEPRECATED_PROPS_MAP[oldProp];
    //             translatedProps[newProp] = newValue;
    //             delete translatedProps[oldProp];
    //             if (process.env.NODE_ENV !== 'production') {
    //                 console.warn(warning);
    //             }
    //         }
    //     }    
    // }

    return translatedProps;
}

export function useCombinedFlexGridStyles(props: BoxProps) {
    return useMemo(() => {
        let {
            display, flex, inline, flexflow, flexWrap, flexoverflow, userselect, select, noselect, cursor, pointer, wrap, wraps, row, col, width, height,
            grow, shrink, basis, flexDirection, full, fullw, fullh, fit, fitw, fith, grid, columns, autocolumns, maxcolumns, widthcolumns, gap, radius, padding, radiusleft, radiusright, radiustop, radiusbottom, radiustopleft, radiustopright, radiusbottomleft, radiusbottomright
        } = props;

        let gridTemplateColumns;
        let isGrid = Boolean(grid || columns || autocolumns);
        let isFlex = !isGrid;

        if (isGrid) {
            display = 'grid';
            const isNumber = typeof columns === 'number';
            const isAutoWithMax = autocolumns && maxcolumns && isNumber;

            gridTemplateColumns = isAutoWithMax
                ? `repeat(${Math.min(columns, maxcolumns)}, 1fr)`
                : isNumber
                    ? `repeat(${columns}, 1fr)`
                    : Array.isArray(columns)
                        ? columns.map(value => `${value}fr`).join(' ')
                        : `repeat(auto-fit, minmax(${widthcolumns}, 1fr))`;
        }

        if (isFlex) {
            if (col) {
                grow = 1;
                flexDirection = 'column';
            }
            if (wraps) {
                display = 'flex';
                flexWrap = 'wrap';
            }
            flex = flex ? flex : `${grow} ${shrink} ${basis}`;
            flexflow = (wrap || wraps) ? 'wrap' : row ? 'row' : 'column';
            flexoverflow = wrap ? 'everywhere' : 'normal'
        }

        let count = 0;
        for (let i = 1; i <= 15; i++) {
            const gapProp = `gap${i}`;
            if (props[gapProp]) {
                gap = gapMap[gapProp];
                count++;
            }
            const radiusProp = `radius${i}`;
            if (props[radiusProp]) {
                radius = radiusMap[radiusProp];
                count++;
            }
            const paddingProp = `padding${i}`;
            if (props[paddingProp]) {
                padding = paddingMap[paddingProp];
                count++;
            }
            if (count == 3) {
                break;
            }
        }

        if (radiustop) {
            radiustopleft = radiustop;
            radiustopright = radiustop;
        }

        if (radiusbottom) {
            radiusbottomleft = radiusbottom;
            radiusbottomright = radiusbottom;
        }

        if (radiusleft) {
            radiustopleft = radiusleft;
            radiusbottomleft = radiusleft;
        }

        if (radiusright) {
            radiustopright = radiusright;
            radiusbottomright = radiusright;
        }

        if (inline || props.isparagraph) {
            display = 'inline-flex';
        }

        width = Number.isFinite(width) ? `${width}%` : fullw || full ? '100%' : fit || fitw ? 'fit-content' : width;
        height = Number.isFinite(height) ? `${height}%` : fullh || full ? '100%' : fit || fith ? 'fit-content' : height;
        userselect = noselect ? 'none' : select ? 'auto' : userselect;
        cursor = pointer ? 'pointer' : cursor;

        return {
            display,
            flex,
            flexflow,
            flexWrap,
            flexoverflow,
            select,
            grow,
            shrink,
            basis,
            flexDirection,
            width,
            height,
            grid,
            columns,
            autocolumns,
            maxcolumns,
            gridTemplateColumns,
            gap,
            radius,
            radiustopright,
            radiustopleft,
            radiusbottomright,
            radiusbottomleft,
            padding,
            userselect,
            cursor
        };
    }, [
        props.display, props.inline, props.flex, props.flexflow, props.flexWrap, props.flexoverflow, props.userselect, props.select, props.noselect, props.cursor, props.pointer,
        props.wrap, props.wraps, props.row, props.col, props.width, props.height, props.grow, props.shrink, 
        props.basis, props.flexDirection, props.full, props.fullw, props.fullh, props.fit, props.fitw, props.fith, props.grid, props.columns, props.autocolumns, props.maxcolumns,
        props.radius, props.padding, props.gap,
        props.radiustop, props.radiusbottom, props.radiusleft, props.radiusright,
        props.radiustopleft, props.radiustopright, props.radiusbottomleft, props.radiusbottomright,
        props.gap1, props.gap2, props.gap3, props.gap4, props.gap5, props, props.gap6, props.gap7, props.gap8, props.gap9, props.gap10, props.gap11, props.gap12, props.gap13, props.gap14, props.gap15,
        props.radius1, props.radius2, props.radius3, props.radius4, props.radius5, props, props.radius6, props.radius7, props.radius8, props.radius9, props.radius10, props.radius11, props.radius12, props.radius13, props.radius14, props.radius15,
        props.padding1, props.padding2, props.padding3, props.padding4, props.padding5, props, props.padding6, props.padding7, props.padding8, props.padding9, props.padding10, props.padding11, props.padding12, props.padding13, props.padding14, props.padding15
    ]);
}

function getSelectedColor(props: BoxProps): string | null {
    // Lista de las claves de colores definidos en el tipo Colors
    const colorKey: Array<keyof Colors> = colorKeys;
  
    // Encuentra la primera clave de color que esté establecida en true y devuélvela.
    const selectedColor = colorKey.find(color => props[color]);
    return selectedColor || null;
  }

export function useFontAndHeadingStyles(props: BoxProps) {
    return useMemo(() => {
        let as = props.as;
        let header = props.header;
        let footer = props.footer;
        let main = props.main;
        let section = props.section;
        let article = props.article;
        let display = props.display;
        let fontSize = props.fontSize;
        let paragraph = props.paragraph;
        let isparagraph = props.isparagraph;
        let p = props.p;
        let span = props.span;
        let fontWeight = props.fontWeight;
        let fontStyle = props.fontStyle;
        let textDecoration = props.textDecoration;
        let textAlign = props.textAlign;
        let textTransform = props.textTransform;

        const selectedColor = getSelectedColor(props);

        let color = selectedColor ? selectedColor : props.color;
        textAlign = props.textcenter ? 'center' : props.textleft ? 'left' : props.textright ? 'right' : props.textjustify ? 'justify' : textAlign;

        let count = 0;
        let found = "";
        let variant = "";
        for (let style in paragraphTags) {
            if (props[style]) {
                const styleProp = styleProps[style];
                if (styleProp === 'fontWeight') {
                    fontWeight = paragraphStyles[style];
                    found = paragraphTags[style];
                    count++;
                } else if (styleProp === 'fontStyle') {
                    fontStyle = paragraphStyles[style];
                    found = paragraphTags[style];
                    count++;
                } else if (styleProp === 'textDecoration') {
                    if (textDecoration == 'none')
                    textDecoration = '';
                    textDecoration = `${textDecoration} ${paragraphStyles[style]}`;
                    found = paragraphTags[style];
                    count++;
                } else if (styleProp == 'textTransform') {
                    textTransform = `${paragraphStyles[style]}`;
                }
            }
        }
        
        if (count >= 1 || paragraph || p) {
            if (count == 1) {
                as = found;
            } else {
                as = 'p';
            }
            isparagraph = true;
            display = 'inline';
        }

        for (let i = 1; i <= 6; i++) {
            const headingProp = `h${i}`;
            if (props[headingProp]) {
                as = `h${i}`;
                break;
            }
        }


        for (let i = 1; i <= 6; i++) {
            const headingProp = `p${i}`;
            if (props[headingProp]) {
                as = 'p';
                variant = i.toString();
                isparagraph = true;
                display = 'inline';
                break;
            }
        }

        for (let i = 0; i <= 15; i++) {
            const fontProp = `font${i}`;
            if (props[fontProp]) {
                fontSize = fontMap[fontProp];
                break;
            }
        }

        if (header) {
            as = 'header';
        }

        if (footer) {
            as = 'footer';
        }

        if (main) {
            as = 'main';
        }

        if (section) {
            as = 'section';
        }

        if (article) {
            as = 'article';
        }

        if (span) {
            as = 'span';
        }

        if (fontSize == 'inherit')
            fontSize = fontSizeMap[as + variant];

        let newprops = {
            as,
            p,
            isparagraph,
            color,
            fontSize,
            fontWeight,
            fontStyle,
            textDecoration,
            textAlign,
            textTransform
        }

        if (display == 'inline') {
            newprops = {
                ...newprops,
                display
            }
        }

        return newprops
    }, [props.as, props.header, props.footer, props.main, props.section, props.article, props.paragraph, props.h1, props.h2, props.h3, props.h4, props.h5, props.h6, props.p, props.p1, props.p2, props.p3, props.p4, props.p5, props.p6, props.span, props.fontSize, props.color, props.isparagraph, props.bold, props.italic, props.strikethrough, props.overline, props.underline, props.fontWeight, props.fontStyle, props.textDecoration, props.textAlign, props.textcenter, props.textleft, props.textright, props.textjustify, props.textTransform, props.capitalize, props.uppercase, props.lowercase,
        props.white, props.silver, props.gray, props.black, props.red, props.maroon, props.yellow, props.olive, props.lime, props.green, props.aqua, props.teal, props.blue, props.navy, props.fuchsia, props.purple, props.orange, props.aliceblue, props.antiquewhite, props.aquamarine, props.azure, props.beige, props.bisque, props.blanchedalmond, props.blueviolet, props.brown, props.burlywood, props.cadetblue, props.chartreuse, props.chocolate, props.coral, props.cornflowerblue, props.cornsilk, props.crimson, props.cyan, props.darkblue, props.darkcyan, props.darkgoldenrod,
    props.font0, props.font1, props.font2, props.font3, props.font4, props.font5, props.font6, props.font7, props.font8, props.font9, props.font10, props.font11, props.font12, props.font13, props.font14, props.font15]);
}

export function usePositionLogic(props: BoxProps) {
    return useMemo(() => {
        let row = props.row;
        let position = props.position;
        let absolute = props.absolute;
        let relative = props.relative;
        let top = props.top;
        let left = props.left;
        let place = props.place;
        let placecenter = props.placecenter;
        let placeleft = props.placeleft;
        let placerigth = props.placeright;
        let placeitems = props.placeitems;
        let placecontent = props.placecontent;
        let center = props.center;
        let centerw = props.centerw;
        let centerh = props.centerh;
        let contentcenter = props.contentcenter;
        let contentleft = props.contentleft;
        let contentright = props.contentright;
        let itemscenter = props.itemscenter;
        let itemsleft = props.itemsleft;
        let itemsright = props.itemsright;
        let spacebetween = props.spacebetween;
        let spacebetweenw = props.spacebetweenw;
        let spacebetweenh = props.spacebetweenh;

        spacebetweenw = spacebetweenw || spacebetween;
        spacebetweenh = spacebetweenh || spacebetween;
        place = (center || centerw) ? 'center' : placecenter ? 'center' : placeleft ? 'left' : placerigth ? 'right' : place;
        placecontent = (center || centerw || contentcenter) ? 'center' : spacebetweenw ? 'space-between' : contentleft ? 'left' : contentright ? 'right' : placecontent;
        placeitems = (center || centerh || itemscenter) ? 'center' : spacebetweenh ? 'space-between' : itemsleft ? 'left' : itemsright ? 'right' : placeitems;

        // Position Logic
        const positionType = relative ? 'relative' : absolute ? 'absolute' : null;

        if (positionType) {
            position = positionType;
            top = props.top || '0px';
            left = props.left || '0px';
            return {
                position: position,
                absolute: absolute,
                relative: relative,
                top: top,
                left: left,
                place: place,
                placeitems: placeitems,
                placecontent: placecontent
            };
        }

        return {
            place: place,
            placeitems: placeitems,
            placecontent: placecontent
        }
    }, [props.row, props.position, props.absolute, props.relative, props.top, props.left, props.place, props.placecenter, props.placeleft, props.placeright, props.placeitems, props.placecontent, props.center, props.centerw, props.centerh, props.contentcenter, props.contentleft, props.contentright, props.itemscenter, props.itemsleft, props.itemsright, props.spacebetween, props.spacebetweenw, props.spacebetweenh]);
}

export function useBackgroundLogic(props: BoxProps) {
    return useMemo(() => {
        let { background, backgroundimage, backgroundcolor, backgroundsize, backgroundposition, backgroundrepeat, backgroundattachment, backgroundclip, backgroundorigin, backgroundnorepeat, backgroundcover, backgroundcontain, backgroundfixed, backgroundcenter, backgroundtop, backgroundbottom, backgroundleft, backgroundright } = props;

        if (background) {
            const replacedBackground = background.replace(/(url\(['"]?)(\/[^'"]+)(['"]?\))/, (match, prefix, url, suffix) => {
                return url.startsWith('/') ? `${prefix}${url}${suffix}` : match;
            });
            background = replacedBackground;
        }

        if (backgroundimage) {
            backgroundimage = `url(${backgroundimage})`;
        }

        if (backgroundsize || backgroundcover || backgroundcontain) {
            backgroundsize = backgroundcover ? 'cover' : backgroundcontain ? 'contain' : backgroundsize;
        }

        if (backgroundposition || backgroundcenter || backgroundtop || backgroundbottom || backgroundleft || backgroundright) {
            backgroundposition = backgroundcenter ? 'center' : backgroundtop ? 'top' : backgroundbottom ? 'bottom' : backgroundleft ? 'left' : backgroundright ? 'right' : backgroundposition;
        }

        return {
            background,
            backgroundimage,
            backgroundcolor,
            backgroundsize,
            backgroundposition,
            backgroundrepeat,
            backgroundattachment,
            backgroundclip,
            backgroundorigin
        };
    }, [props.background, props.backgroundimage, props.backgroundcolor, props.backgroundsize, props.backgroundposition, props.backgroundrepeat, props.backgroundattachment, props.backgroundclip, props.backgroundorigin, props.backgroundnorepeat, props.backgroundcover, props.backgroundcontain, props.backgroundfixed, props.backgroundcenter, props.backgroundtop, props.backgroundbottom, props.backgroundleft, props.backgroundright]);
}

export function useMemoizedChildren(props: BoxProps) {
    return useMemo(() => {
        let children = props.children;
        let sharedProps = props.sharedProps;
        let isparagraph = props.isparagraph;
        let mobile = props.mobile;

        const childCount = Children.count(children);
        const isWraps = props.wraps && childCount > 0;
        let hasSharedProps = 0;
        if (sharedProps) {
            hasSharedProps = Object.keys(sharedProps).length
        }

        if (isWraps || hasSharedProps > 0 || isparagraph) {
            let basis = `calc(90% / ${childCount})`;
            let col = true;

            if (isWraps) {
                mobile = `
			  flex-direction: column;
			  ${mobile}
			`;
            }
            // Clone children with the calculated basis and col enabled
            return Children.map(children, (child) => {
                if (isValidElement(child)) {
                    const childProps = child.props;

                    if (isWraps) {
                        const childHasBasis = 'basis' in childProps;

                        // If `basis` is not defined for the child or is 'auto', use the shared `basis` prop
                        basis = childHasBasis && childProps.basis !== 'auto' ? childProps.basis : basis;

                        return cloneElement(child, { ...sharedProps, basis, col, isparagraph: isparagraph });
                    } else {
                        return cloneElement(child, { ...sharedProps, isparagraph: isparagraph });
                    }
                }
                return child;
            });
        }

        return children;
    }, [props.children, props.sharedProps, props.wraps, props.isparagraph]);
}