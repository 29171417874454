import React, { ReactNode } from 'react';
import { Box } from '@/common/layout/box';
import { Form } from '@/common/forms/form';
import { Input } from '@/common/inputs/input';
import { Button } from '@/ui/button';

export type ContactUsProps = {
    /**
     * A node to be rendered in the special component.
     */
    children?: ReactNode;
};

export function ContactUs({ children }: ContactUsProps) {

    return (
        <Box article gap6 textjustify>
            <Box background="#0e3f6a" padding="2rem 2%" gap10 white>
                <Box h1>
                    Contacto
                </Box>
                <Box h2 font9>
                    En el Departamento de Ingeniería Industrial de la Universidad de Concepción, valoramos la comunicación abierta y estamos comprometidos a estar accesibles para nuestros estudiantes, futuros estudiantes, colegas académicos y socios de la industria. Si tienes alguna pregunta, necesitas más información o deseas explorar oportunidades de colaboración, no dudes en ponerte en contacto con nosotros.
                </Box>
            </Box>
            <Box gap6 padding="2rem 2%">
                <Box h2>
                    Información de Contacto
                </Box>
                <Box p>
                    Dirección: Edmundo Larenas 219, 4to piso. Ciudad Universitaria, Concepción, Región del Biobío, Chile.<br/>
                    Teléfono: +56 41 2203620<br/>
                    Correo Electrónico: dii_secdoc@udec.cl
                </Box>
                <Box h2>
                    Formulario de Consulta
                </Box>
                <Box p>
                    Para preguntas específicas o solicitudes, por favor, rellena el siguiente formulario y nos pondremos en contacto contigo a la mayor brevedad posible.
                </Box>
                <Form gap10>
					<Input name="username" placeholder="Nombre completo" required></Input>
					<Input name="email" placeholder="Correo electrónico" required></Input>
					<Input name="subject" placeholder="Asunto" required></Input>
					<Input as="textarea" name="message" placeholder="Mensaje" height="150px" required></Input>
					<Button type="submit">Enviar</Button>
				</Form>
            </Box>
            {children}
        </Box>
    );
}
