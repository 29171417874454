import React, { createContext, ReactNode, useContext } from 'react';
import { useMergeState } from '@/hooks/use-merge-state';

export type ContextProps = {
	children?: ReactNode;
	value?: object;
};

type ContextType = {
	web: any | null;
	setWeb: any;
};

const defaultContextValue: ContextType = {
	web: null,
	setWeb: () => { },
};

export const WebContext = createContext<ContextType>(defaultContextValue);

export function WebProvider({ children, value = {}, ...props }: ContextProps) {
	const [web, setWeb] = useMergeState(value);

	return (
		<WebContext.Provider value={{ web, setWeb }}>
			{children}
		</WebContext.Provider>
	);
}

export function useWeb() {
	const context = useContext(WebContext);
	if (!context) {
		throw new Error('useWeb must be used within a WebProvider');
	}
	return context;
}