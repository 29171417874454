import React, { ReactNode, MouseEvent, useEffect, useState } from 'react';
import { Box, BoxProps } from '@/common/layout/box';
import { Link as LinkComponent } from 'react-router-dom';

export type LinkProps = {
	children?: ReactNode;
	href?: string;
	smoothScroll?: boolean;
	preserveScroll?: boolean;
} & BoxProps;

export function Link({ children, href = "/", smoothScroll = true, preserveScroll = false, ...props }: LinkProps) {
	const isInternalLink = href && href.startsWith('#');
	const asProp = isInternalLink ? 'a' : LinkComponent;

	const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
		if (!preserveScroll && !isInternalLink) {
			// Scroll al inicio de la página si preserveScroll es false
			window.scrollTo(0, 0);
			return;
		}

		if (smoothScroll && isInternalLink) {
			event.preventDefault();
			const targetId = href?.substring(1);
			const targetElement = document.getElementById(targetId || '');
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth' });
			}
		}
	};

	// Al cambiar de página, guardar la posición actual del scroll
	useEffect(() => {
		const handleScroll = () => {
			sessionStorage.setItem('scrollPosition', window.scrollY.toString());
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	// Al cargar la página, restaurar la posición del scroll si es necesario
	useEffect(() => {
		if (preserveScroll) {
			const scrollPosition = sessionStorage.getItem('scrollPosition');
			if (scrollPosition) {
				window.scrollTo(0, parseInt(scrollPosition));
			}
		}
	}, [preserveScroll]);

	return (
		<Box
			as={asProp}
			to={isInternalLink ? undefined : href}
			href={isInternalLink ? href : undefined}
			row
			width="max-content"
			cursor="pointer"
			onClick={handleClick}
			{...props}
		>
			{children}
		</Box>
	);
}
