import { Box } from '@/common/layout/box';
import { Link } from '@/common/navigation/link';
import { Nav, List } from '@/common/navigation/nav';
import React, { ReactNode, useContext } from 'react';
import styled from 'styled-components';


export type AdminNavbarProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function AdminNavbar({ children }: AdminNavbarProps) {
	return (
		<Box wrap>
			<Box as="h1" color="white">Panel de control</Box>
			{children}
		</Box>
	);
}
