import React, { useEffect, useState } from 'react';

export function useScroll() {
	const [scroll, setScroll] = useState<number>(0);

	useEffect(() => {
		const handleScroll = () => {
			setScroll(window.scrollY);
		};

		// Add a scroll event listener
		window.addEventListener('scroll', handleScroll);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [window.scrollY]);

	return scroll;
}