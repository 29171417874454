import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { useBackgroundLogic, useCombinedFlexGridStyles, useFontAndHeadingStyles, useMemoizedChildren, usePositionLogic } from './box-functions';
import { useIsMobileCond } from '@/hooks/use-is-mobile-cond';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { BoxProps } from '.';

export function Box({ innerRef, ...props }: BoxProps) {

	props.wraps = props.wraps ? useIsMobileCond() : false;
	props.row = props.mrow ? useIsMobileCond() : props.row;
	props.grid = props.mgrid ? useIsMobileCond() : props.grid;

	const positionStyles = usePositionLogic(props);
	const fontStyles = useFontAndHeadingStyles(props);
	const gridStyles = useCombinedFlexGridStyles(props);
	// const StylingStyles = useStylingLogic(props);
	const backgroundStyles = useBackgroundLogic(props);

	const restProps = {
		...props,
		...positionStyles,
		...gridStyles,
		...fontStyles,
		// ...StylingStyles,
		...backgroundStyles
	};

	const memoizedChildren = useMemoizedChildren(restProps);

	return (
		<>
			{
				props.active && (props.noshape ?
				<Box relative
					place={restProps.place}
					placeitems={restProps.placeitems}
					placecontent={restProps.placecontent}
				>
					<Box {...restProps}
						ref={innerRef}
						place={Box.defaultProps.place}
						placeitems={Box.defaultProps.placeitems}
						placecontent={Box.defaultProps.placecontent}
						absolute
						maxwidth="100wv"
						noshape={false}
					>
						{memoizedChildren}
					</Box>
				</Box>
				:
				<BoxStyle ref={innerRef} {...restProps}>
					{memoizedChildren}
				</BoxStyle>)
			}
		</>
	);
}

Box.defaultProps = {
	index: 1,
	active: true,
	flexflow: 'unset',
	placeitems: 'initial',
	placecontent: 'initial',
	place: 'auto',
	align: 'auto',
	grow: 0,
	shrink: 1,
	basis: 'auto',
	breakpoint: '615px',
	width: 'auto',
	height: 'auto',
	maxwidth: '100%',
	maxheight: 'unset',
	minwidth: 'initial',
	minheight: 'initial',
	margin: 'initial',
	padding: '0',
	position: 'initial',
	color: 'unset',
	fontSize: 'inherit',
	fontFamily: 'unset',
	textalign: 'unset',
	textDecoration: 'none',
	filter: 'none',
	boxshadow: 'none',
	boxsizing: 'none',
	textshadow: 'none',
	opacity: 'initial',
	gap: '0',
	border: 'none',
	outline: 'none',
	radius: 'none',
	display: 'flex',
	before: null,
	right: 'initial',
	left: 'initial',
	top: 'initial',
	bottom: 'initial',
	transform: 'initial',
	transition: 'initial',
	pointerevents: 'initial',
	cursor: 'initial',
	userselect: 'inherit',
	overflow: 'initial',
	scale: 'initial',
	widthcolumns: '100px'
};


const BoxStyle = styled.div<BoxProps>`
  ${props => props.index && `z-index: ${props.index};`}
  ${props => props.background && `background: ${props.background};`}
  ${props => props.backgroundimage && `background-image: ${props.backgroundimage};`}
  ${props => props.backgroundsize && `background-size: ${props.backgroundsize};`}
  ${props => props.backgroundposition && `background-position: ${props.backgroundposition};`}
  ${props => props.backgroundrepeat && `background-repeat: ${props.backgroundrepeat};`}
  ${props => props.backgroundattachment && `background-attachment: ${props.backgroundattachment};`}
  ${props => props.backgroundclip && `background-clip: ${props.backgroundclip};`}
  ${props => props.backgroundorigin && `background-origin: ${props.backgroundorigin};`}
  ${props => props.place && `place-self: ${props.place};`}
  ${props => props.placecontent && `place-content: ${props.placecontent};`}
  ${props => props.placeitems && `place-items: ${props.placeitems};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.margintop && `margin-top: ${props.margintop};`}
  ${props => props.marginbottom && `margin-bottom: ${props.marginbottom};`}
  ${props => props.marginleft && `margin-left: ${props.marginleft};`}
  ${props => props.marginright && `margin-right: ${props.marginright};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.paddingtop && `padding-top: ${props.paddingtop};`}
  ${props => props.paddingbottom && `padding-bottom: ${props.paddingbottom};`}
  ${props => props.paddingleft && `padding-left: ${props.paddingleft};`}
  ${props => props.paddingright && `padding-right: ${props.paddingright};`}
  ${props => props.position && `position: ${props.position};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.height && `height: ${props.height};`}
  ${props => props.maxwidth && `max-width: ${props.maxwidth};`}
  ${props => props.maxheight && `max-height: ${props.maxheight};`}
  ${props => props.minwidth && `min-width: ${props.minwidth};`}
  ${props => props.minheight && `min-height: ${props.minheight};`}
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.flexflow && `flex-flow: ${props.flexflow};`}
  ${props => props.flexoverflow && `overflow-wrap: ${props.flexoverflow};`}
  ${props => props.filter && `filter: ${props.filter};`}
  ${props => props.boxshadow && `box-shadow: ${props.boxshadow};`}
  ${props => props.boxsizing && `box-sizing: ${props.boxsizing};`}
  ${props => props.textshadow && `text-shadow: ${props.textshadow};`}
  ${props => props.opacity && `opacity: ${props.opacity};`}
  ${props => props.fontFamily && `font-family: ${props.fontFamily};`}
  ${props => props.color && `color: ${props.color};`}
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
  ${props => props.textDecoration && `text-decoration: ${props.textDecoration};`}
  ${props => props.textTransform && `text-transform: ${props.textTransform};`}
  ${props => props.gap && `gap: ${props.gap};`}
  ${props => props.border && `border: ${props.border};`}
  ${props => props.outline && `outline: ${props.outline};`}
  ${props => props.radius && `border-radius: ${props.radius};`}}
  ${props => props.radiustopleft && `border-top-left-radius: ${props.radiustopleft};`}}
  ${props => props.radiustopright && `border-top-right-radius: ${props.radiustopright};`}}
  ${props => props.radiusbottomleft && `border-bottom-left-radius: ${props.radiusbottomleft};`}}
  ${props => props.radiusbottomright && `border-bottom-right-radius: ${props.radiusbottomright};`}}
  ${props => props.display && `display: ${props.display};`}
  ${props => props.visibility && `visibility: ${props.visibility};`}
  ${props => props.left && `left: ${props.left};`}
  ${props => props.right && `right: ${props.right};`}
  ${props => props.top && `top: ${props.top};`}
  ${props => props.bottom && `bottom: ${props.bottom};`}
  ${props => props.animation && `animation: ${props.animation};`}
  ${props => props.transform && `transform: ${props.transform};`}
  ${props => props.transition && `transition: ${props.transition};`}
  ${props => props.pointerevents && `pointer-events: ${props.pointerevents};`}
  ${props => props.cursor && `cursor: ${props.cursor};`}
  ${props => props.userselect && `user-select: ${props.userselect};`}
  ${props => props.overflow && `overflow: ${props.overflow};`}
  ${props => props.scale && `scale: ${props.scale};`}
  ${props => props.gridTemplateColumns && `grid-template-columns: ${props.gridTemplateColumns};`}
  ${props => props.gridflow && `grid-auto-flow: ${props.gridflow};`}
  ${props => props.wordbreak && `word-break: ${props.wordbreak};`}
  
  ${props => props.mobile && `
    @media (max-width: 615px) {
      ${props.mobile}
    }
  `}

  ${props => props.notmobile && `
    @media (max-width: 680px) {
		display: none;
    }
  `}
  
  ${props => props.nomobile && `
    @media (min-width: 680px) {
      ${props.nomobile}
    }
  `}

  ${props => props.colorgradient && `
	-webkit-text-fill-color: transparent;
	background-image: linear-gradient(110deg,${props.colorgradient[0]} 0%,${props.colorgradient[1]} 66%);
	-webkit-background-clip: text;
  `}
  
  ${props => props.sbefore && `&:before {${props.sbefore}}`}
  ${props => props.safter && `&:after {${props.safter}}`}
  ${props => props.shover && `&:hover {${props.shover}}`}
  ${props => props.sactive && `&:active {${props.sactive}}`}
  ${props => props.sdisabled && `&:disabled {${props.sdisabled}}`}
  ${props => props.sfocus && `&:focus {${props.sfocus}}`}
  ${props => props.splaceholder && `&:placeholder {${props.splaceholder}}`}
  ${props => props.styles && `${props.styles}`}
`;