import { useAxios } from '@/hooks/use-axios';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@/common/layout/box';
import { Form, FormProps } from '@/common/forms/form';
import { useMergeState } from '@/hooks/use-merge-state';

export type ConsoleProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
} & FormProps;

export function Console({ children, ...rest }: ConsoleProps) {
	const [historyPosition, sethistoryPosition] = useState(0);
	const contentRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const [data, setData] = useMergeState({
		username: 'Guest',
		servername: '?',
		folder: '/'
	});
	
	const [history, setCommand] = useState<any>([]);

	const addCommand = (entry: any) => {
		const newHistory = {
			username: data.username,
			servername: data.servername,
			folder: data.folder,
			command: entry.cmd
		}
        setCommand((oldObject: any) => [...oldObject, newHistory]);
    };

	const addEntry = (entry: any) => {
		setData({
			username: data.username,
			servername: data.servername,
			folder: entry.folder
		})
		const newHistory = {
			command: entry.response
		}
        setCommand((oldObject: any) => [...oldObject, newHistory]);
    };

	const ConsoleConfig = useAxios({
		url: '/console/config',
		onSuccess: setData
	}, true);

	useEffect(() => {
		console.log(history);
		contentRef.current.scrollTop = contentRef.current.scrollHeight;
		inputRef.current.value = '';
	},[history]);

	const CmdKeyDown = (event: any) => {
		switch (event.key) {

			case "ArrowUp":
				if (historyPosition > 0) {
					sethistoryPosition(historyPosition-1)
					inputRef.current.blur();
					inputRef.current.value = history[historyPosition].command;
					// _defer(function() {
					// 	eShellCmdInput.focus();
					// });
				}
				break;
			case "ArrowDown":
				if (historyPosition >= history.length) {
					break;
				}
				sethistoryPosition(historyPosition+1)
				if (historyPosition === history.length) {
					inputRef.current.value = '';
				} else {
					inputRef.current.blur();
					inputRef.current.focus();
					inputRef.current.value = history[historyPosition].command;
				}
				break;
		}
	}
	
	return (
		<Box as={ConsoleStyle} background="#222">
			<ConsoleContentStyle ref={contentRef}>
				<ConsoleLogoStyle>
					<span>     _          _ _        _  _   </span>
					<span> ___| |__   ___| | |_ /\/|| || |_ </span>
					<span>/ __| '_ \ / _ \ | (_)/\/_  ..  _|</span>
					<span>\__ \ | | |  __/ | |_   |_      _|</span>
					<span>|___/_| |_|\___|_|_(_)    |_||_|  </span>
					<span>                                  </span>
				</ConsoleLogoStyle>
				{Object.keys(history).map((item, i) => (
					<CommandStyle key={crypto.randomUUID()}>
						<ConsolePrompt>
							{ history[item].username &&
								<>
									<ConsoleID>{history[item].username}@{history[item].servername}:</ConsoleID>
									<span> {history[item].folder} </span>#
								</>
							}
							{history[item].command}
						</ConsolePrompt>
					</CommandStyle>
				))}
			</ConsoleContentStyle>
            <Box as={ConsoleInput} row>
                <ConsolePrompt>
					<ConsoleID>{data.username}@{data.servername}:</ConsoleID>
					<span> {data.folder} </span>#
				</ConsolePrompt>
                <Form col submit="/console/api" values={addCommand} callback={addEntry} onStream={() => {console.log("hellos")}} {...rest}>
                    <input name="cmd" ref={inputRef} onKeyDown={CmdKeyDown} autoComplete="off" required/>
                </Form>
            </Box>
			{children}
		</Box>
	);
}

export const ConsoleStyle = styled.div`
& {
	box-shadow: 0 0 5px rgba(0, 0, 0, .3);
	font-size: 10pt;
	align-items: stretch;
	font-family: monospace;
}
`;

export const ConsoleContentStyle = styled.pre`
& {
	height: 500px;
	overflow: auto;
	padding: 5px;
	white-space: pre-wrap;
	flex-grow: 1;
}
&::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
&::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}
&::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
&::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}
&::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
}
&::-webkit-scrollbar-track:hover,
&::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
`;

export const ConsoleLogoStyle = styled.div`
& {
	font-weight: bold;
	color: #FF4180;
	text-align: center;
}
& > span {
	display:block;
}
`;

export const CommandStyle = styled.span`
& {
	color: #eee;
	display:block;
}
`;

export const ConsoleInput = styled.div`
& {
	box-shadow: 0 -1px 0 rgba(0, 0, 0, .3);
	border-top: rgba(255, 255, 255, .05) solid 1px;
}

& > label {
	flex-grow: 0;
	display: block;
	padding: 0 5px;
	height: 30px;
	line-height: 30px;
}

& div {
	flex-grow: 1;
	align-items: stretch;
}

& input {
	outline: none;
	height: 30px;
	line-height: 30px;
	border: none;
	background: transparent;
	color: #eee;
	font-family: monospace;
	font-size: 10pt;
	width: 100%;
	align-self: center;
}
`;

export const ConsolePrompt = styled.label`
& {
	font-weight: bold;
	color: #fff;
}

& > span {
	color: #1BC9E7;
}
`;

export const ConsoleID = styled.label`
& {
	font-weight: bold;
	color: #75DF0B;
}
`;