import React, { ReactNode, useState } from 'react';
import { Button } from '@/common/buttons/button';
import { Box } from '@/common/layout/box';
import { useAxios } from '@/hooks/use-axios';
import { useMergeState } from '@/hooks/use-merge-state';
import { useData } from '@/context/data';
import { Image } from '@/common/content/image';
import { Link } from '@/common/navigation/link';
import Banner from './images/banner.png';
import Online from './images/online.gif';
import Offline from './images/offline.gif';
import Dialogue from './images/dialogue.gif';
import { useIsMobileCond } from '@/hooks/use-is-mobile-cond';

export type MeProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function Me({ children }: MeProps) {
	const { data, fetch } = useData()
	const [news, setNews] = useMergeState({});
	const [activeNews, setActiveNews] = useState(0);

	const NewsList = useAxios({
		url: '/news/list',
		onSuccess: setNews,
		values: {
			limit: 3
		}
	}, true);

	const urlRegex = /\s/g;

	return (
		<Box padding="2rem 2%" gap6>
			{/* <Box gap8>
				<Box h3>Historias</Box>
				<Box row gap10>
					<Box
						background="url(https://images.habbux.me/camera/photos/681_1654211944.png) center"
						border="1px solid #000000"
						width="100px"
						height="100px"
						radius1
					>
					</Box>
				</Box>
			</Box> */}
			{/* <Box columns={useIsMobileCond(1,[2, 1])} gap6>
				<Box
					backgroundimage={Banner}
					backgroundcover
					padding10
					gap10
					radius10
					white
				>
					<Box row placeitems="center" gap10>
						<Link href={`/profile/${data.user.username}`} color="white"><Box h2>{data.user.username}</Box></Link>
						<Image src={data.user.online ? Online : Offline} alt={`Estado de ${data.user.username}`} />
					</Box>
					<Box row gap8>
						<Box background="#383838a6" radius12>
							<Avatar figure={userFigure} direction={2} />
						</Box>
						<Box gap8>
							<Box background="#383838a6" row centerw padding11 radius12 gap10>
								<Image src={Dialogue} alt={`Misión de ${data.user.username}`} />
								<Box p>{data.user.motto}</Box>
							</Box>
							<Box background="#383838a6" padding11 radius12>
								<Box>Creado en:</Box>
								<Box>Último acceso:</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box> */}
			<Box columns={useIsMobileCond(1,[2, 1])} gap6>
				<Box gap6>
					<Box columns={useIsMobileCond(1,[1, 2])} white background="gray" radius10 overflow="hidden">
						<Box>
							{news.result && Object.keys(news.result).map((key, i) => (
								<Box key={i} background={activeNews == i && "#548687"} onClick={() => setActiveNews(i)} gap10 padding6>
									<Box h3 font9>{news.result[key].title}</Box>
									<Box p>{news.result[key].shortstory}</Box>
								</Box>
							))}
						</Box>
						{news.result &&
							<Box
								background={`linear-gradient(
									rgba(0, 0, 0, 0.5),
									rgba(0, 0, 0, 0.5)
									), url(${news.result[activeNews].image})`}
								backgroundcover
							>
								<Box gap10 padding6>
									<Link href={`/article/${news.result[activeNews].title.toLowerCase().replace(urlRegex, '-')}`}>
										<Box h3>{news.result[activeNews].title}</Box>
									</Link>
									<Box p4>{news.result[activeNews].shortstory}</Box>
								</Box>
							</Box>
						}
					</Box>
				</Box>
				<Box>

				</Box>
			</Box>
		</Box>
	);
}