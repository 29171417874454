import React, { ReactNode, useEffect, useRef, useState, createContext, useContext } from 'react';
import { Box } from '@/common/layout/box';
import { Image } from '@/common/content/image';
import { Logo } from '@/ui/logo';
import Frank from './images/frank.gif';
// import Music from './audios/music.mp3';
import Audio1 from './audios/audio_1.mp3';
import Audio2 from './audios/audio_2.mp3';
import Audio3 from './audios/audio_3.mp3';
import Audio4 from './audios/audio_4.mp3';
import Audio5 from './audios/audio_5.mp3';
import Audio6 from './audios/audio_6.mp3';
import Nux1 from './images/nux_room_10_round.png';
import Nux2 from './images/nux_room_11_round.png';
import Nux3 from './images/nux_room_12_round.png';
import Cuack from './images/cuack.png';
import { Form } from '@/common/forms/form';
import { Input } from '@/common/inputs/input';
import { BigButton } from '@/ui/button';
import { Link } from '@/common/navigation/link';
import { useMergeState } from '@/hooks/use-merge-state';
import { useNavigate } from 'react-router-dom';
import { useData } from '@/context/data';
import { useIsMobile } from '@/hooks/use-is-mobile';

export type RegisterProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;

	setStep?: any;

	setFormdata?: any;
};

export const Context = createContext<any>({});

export function Register({ children }: RegisterProps) {
	const { data, setData } = useData();
	const [step, setStep] = useState(0);
	const [formdata, setFormdata] = useMergeState({});
	const navigate = useNavigate();

	useEffect(() => {
		if (data) {
			if (data.user)
                if (data.register == 5)
                    navigate('/me');
		}
		if (data.register >= 1) {
			setStep(data.register)
		}
	}, [])
	
	// const musicRef = useRef();

	// useEffect(() => {
	// 	const music = musicRef.current;
	// 	music.volume = 0.1;
	// }, []);

	return (
		<Box height="100vh" background="radial-gradient(circle, rgba(31,170,224,1) 0%, rgba(31,116,224,1) 100%)" white overflow="auto">
			{/* <audio ref={musicRef} src={Music} autoPlay /> */}

			<Context.Provider value={ { formdata, setFormdata } } >
				{ step == 0 &&
					<Step0 setStep={setStep} />
				}
				{
					(step > 0 && step < 5) &&
					<Box padding5 fullh>
					{ step == 1 &&
						<Step1 setStep={setStep} />
					}
					{ step == 2 &&
						<Step2 setStep={setStep} />
					}
					{ step == 3 &&
						<Step3 setStep={setStep} />
					}
					{ step == 4 &&
						<Step4 setStep={setStep} />
					}
					</Box>
				}
				{ step == 5 &&
					<Step5 setStep={setStep} />
				}
				{children}
			</Context.Provider>
		</Box>
	);
}

export function Step0({ children, setStep }: RegisterProps) {
	const audioRef = useRef();
	const [progress, setProgress] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [isReady, setIsReady] = useState(false);
	const [message, setMessage] = useState('Abriendo las puertas de Habbux para ti... ¡Prepara tu avatar y ensaya tu mejor saludo virtual!');
	const [percent, setPercent] = useState(0);

	const handleTimeUpdate = () => {
		const current = audioRef.current;
		const prog = (current.currentTime / current.duration) * 100;
		setProgress(prog);
		setCurrentTime(current.currentTime);
		if (prog >= 100) {
			setIsReady(true);
			setPercent(100);
			setStep(1);
		}
	};

	const loadingMessages = [
        'Preparando la diversión...',
        'Afinando los detalles del lobby...',
        'Limpiando las habitaciones virtuales...',
        'Asegurando la mejor experiencia en Habbux...',
        'Revisando la lista de actividades...',
        'Poniendo a punto las sorpresas del día...',
        'Calentando la pista de baile...',
        'Comprobando el estado del servidor...',
        '¡Preparándonos para tu llegada!',
        // ... más mensajes
    ];

	const randomizeMessage = () => {
        const randomIndex = Math.floor(Math.random() * loadingMessages.length);
        setMessage(loadingMessages[randomIndex]);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if(percent < 100) randomizeMessage();
        }, 2000);
    
        return () => clearInterval(interval);
    }, [ percent ]);

	useEffect(() => {
		const audio = audioRef.current;
		audio.addEventListener('timeupdate', handleTimeUpdate);

		return () => {
			audio.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, []);

	return (
		<Box fullh center>
			<Box gap="20px" center>
				<audio ref={audioRef} src={Audio1} autoPlay />

				<Logo />
				<Percent value={parseInt(progress)} />
				<Box p4 center>
					{message}
				</Box>
			</Box>
			<Image absolute right="5%" bottom="0" src={Frank}></Image>
		</Box>
	);
}

export function Step1({ children, setStep }: RegisterProps) {
	const { data, setData } = useData();
	const audioRef = useRef();
	const [progress, setProgress] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const { formdata, setFormdata } = useContext(Context);

	const handleTimeUpdate = () => {
		const current = audioRef.current;
		const prog = (current.currentTime / current.duration) * 100;
		setProgress(prog);
		setCurrentTime(current.currentTime);
	};

	useEffect(() => {
		const audio = audioRef.current;
		audio.addEventListener('timeupdate', handleTimeUpdate);

		return () => {
			audio.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, []);

	return (
		<Box gap5 fullh>
			<audio ref={audioRef} src={Audio2} autoPlay />

			<Box gap5>
				<Logo />
				<Box gap10>
					<Box h1>Diseña Tu Único Habbux</Box>
					<Box p font9>La personalidad brilla en Habbux Hotel. Aquí, tu avatar es más que una figura digital; es una expresión de tu creatividad sin límites. Elige un nombre, selecciona tu estilo, y luce desde un peinado punk rosa hasta la ropa más chic. Sé tú mismo en una realidad donde las únicas barreras son las que decides poner.</Box>
				</Box>
			</Box>
			<Form submit="/user/check/username" callback={() => setStep(2)} values={setFormdata} gap5 fullh spacebetween>
				<Input name="username" label="Nombre de usuario" value={data.user && data.user.username} disabled={data.user && true} required />
				<BigButton>¡Listo!, ¡Comenzemos ya!</BigButton>
				{children}
			</Form>
			<Image absolute right="5%" bottom="0" src={Frank}></Image>
		</Box>
	);
}


export function Step2({ children, setStep }: RegisterProps) {
	const { data, setData } = useData();
	const audioRef = useRef();
	const [progress, setProgress] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const { formdata, setFormdata } = useContext(Context);

	const handleTimeUpdate = () => {
		const current = audioRef.current;
		const prog = (current.currentTime / current.duration) * 100;
		setProgress(prog);
		setCurrentTime(current.currentTime);
	};

	useEffect(() => {
		const audio = audioRef.current;
		audio.addEventListener('timeupdate', handleTimeUpdate);

		return () => {
			audio.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, []);

	return (
		<Box gap5 fullh>
			<audio ref={audioRef} src={Audio3} autoPlay />

			<Box gap5>
				<Logo />
				<Box gap10>
					<Box h1>Haz Oficial Tu Estancia</Box>
					<Box p font9>Estás a un paso de la puerta principal. Solo necesitamos algunos detalles para asegurarnos de que tu viaje en Habbux sea seguro y sin interrupciones. Proporciona un correo electrónico y una contraseña robusta, y estarás en camino a la diversión. No olvides aceptar nuestras políticas de privacidad y términos de servicio. ¡Tu privacidad y seguridad son nuestras prioridades!</Box>
				</Box>
			</Box>
			<Form submit="/user/check/formdata" callback={() => setStep(3)} values={setFormdata} gap5 fullh spacebetween>
				<Box gap9>
					<Input name="email" label="Correo electrónico" value={data.user && data.user.email} disabled={data.user && true} required />
					<Input name="birthday" type="date" label="Fecha de nacimiento" value={data.user && data.user.birthday} disabled={data.user && true} required />
					<Input name="password" label="Contraseña" type="password" value={data.user && "strongpassword"} disabled={data.user && true} required />
					<Input name="password_repeat" label="Repita su contraseña" value={data.user && "strongpassword"} disabled={data.user && true} type="password" required />
					{
						useIsMobile() &&
						<Box black background="white" radius10 padding10>
							<Input type="checkbox" name="terms" required>
								<Box p>Acepto los <Link href="/terms-and-service" blue underline>Términos y condiciones</Link></Box>
							</Input>
						</Box>
					}
				</Box>
				<Box row gap5>
					<BigButton>¡Listo!, ¡Comenzemos ya!</BigButton>
					{
						!useIsMobile() &&
						<Box black background="white" radius10 padding10>
							<Input type="checkbox" name="terms" required>
								<Box p>Acepto los <Link href="/terms-and-service" blue underline>Términos y condiciones</Link></Box>
							</Input>
						</Box>
					}
				</Box>
				{children}
			</Form>
			<Image absolute right="5%" bottom="0" src={Frank}></Image>
		</Box>
	);
}

export function Step3({ children, setStep }: RegisterProps) {
	const audioRef = useRef();
	const [progress, setProgress] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const { formdata, setFormdata } = useContext(Context);

	const handleTimeUpdate = () => {
		const current = audioRef.current;
		const prog = (current.currentTime / current.duration) * 100;
		setProgress(prog);
		setCurrentTime(current.currentTime);
	};

	useEffect(() => {
		const audio = audioRef.current;
		audio.addEventListener('timeupdate', handleTimeUpdate);

		return () => {
			audio.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, []);

	const handleData = (formdata: any) => {
		setFormdata(formdata);
	}

	return (
		<Box gap5>
			<audio ref={audioRef} src={Audio4} autoPlay />

			<Box gap5>
				<Logo />
				<Box gap10>
					<Box h1>Selecciona Tu Habitación Habbux</Box>
					<Box p font9>Todo Habbux necesita un hogar, y tú no eres la excepción. ¿Optarás por la serenidad de un rincón zen, el dinamismo de una suite tecnológica, o la relajación de una terraza moderna? Elige tu primera habitación y haz de ella tu santuario personal.</Box>
				</Box>
			</Box>
			<Form submit="/user/register" callback={() => setStep(4)} include={formdata} gap5>
				<Box row center gap5>
					<Input type="radio" name="room" value="1" display="none">
						<Image src={Nux1} styles={`input[type="radio"]:checked + & { transform: scale(1.1); transition: transform 0.3s ease; }`} ></Image>
					</Input>
					<Input type="radio" name="room" value="2" display="none">
						<Image src={Nux2} styles={`input[type="radio"]:checked + & { transform: scale(1.1); transition: transform 0.3s ease; }`}></Image>
					</Input>
					<Input type="radio" name="room" value="3" display="none">
						<Image src={Nux3} styles={`input[type="radio"]:checked + & { transform: scale(1.1); transition: transform 0.3s ease; }`}></Image>
					</Input>
				</Box>
				<BigButton>¡Listo!, ¡Comenzemos ya!</BigButton>
				{children}
			</Form>
			<Image absolute right="5%" bottom="0" src={Frank}></Image>
		</Box>
	);
}

export function Step4({ children, setStep }: RegisterProps) {
	const audioRef = useRef();
	const [progress, setProgress] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);

	const handleTimeUpdate = () => {
		const current = audioRef.current;
		const prog = (current.currentTime / current.duration) * 100;
		setProgress(prog);
		setCurrentTime(current.currentTime);
	};

	useEffect(() => {
		const audio = audioRef.current;
		audio.addEventListener('timeupdate', handleTimeUpdate);

		return () => {
			audio.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, []);

	return (
		<Box gap5>
			<audio ref={audioRef} src={Audio5} autoPlay />

			<Box gap5>
				<Logo />
				<Box gap10>
					<Box h1>Recibe Tus Primeros Cuacks</Box>
					<Box p font9>Como muestra de nuestra hospitalidad, aquí tienes algunos Cuacks para dar inicio a tu legendaria colección. Úsalos para adquirir artículos exclusivos, participar en eventos únicos, o intercambiar con otros Habbux. ¡La economía de Habbux está a tus pies!</Box>
				</Box>
			</Box>
			<Form submit="auth/register" gap5>
				<Image src={Cuack} center ></Image>
				<BigButton onClick={() => setStep(5)}>¡Listo!, ¡Comenzemos ya!</BigButton>
				{children}
			</Form>
			<Image absolute right="5%" bottom="0" src={Frank}></Image>
		</Box>
	);
}

export function Step5({ children, setStep }: RegisterProps) {
	const { data, setData } = useData();
	const audioRef = useRef();
	const [progress, setProgress] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const { formdata, fetch } = useData();
	const navigate = useNavigate();

	const handleTimeUpdate = () => {
		const current = audioRef.current;
		const prog = (current.currentTime / current.duration) * 100;
		setProgress(prog);
		setCurrentTime(current.currentTime);
		if (prog >= 100) {
			// Redireccionar a la página de inicio
            navigate('/client');

            // Recargar la página después de un pequeño retraso para asegurar que la redirección ha ocurrido
			if (!data.user)
            setTimeout(() => {
                window.location.reload();
            }, 100);
		}
	};

	useEffect(() => {
		const audio = audioRef.current;
		audio.addEventListener('timeupdate', handleTimeUpdate);
		fetch.post();

		return () => {
			audio.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, []);

	return (
		<Box fullh gap5 center padding5>
			<audio ref={audioRef} src={Audio6} autoPlay />

			<Box gap5 center>
				<Logo />
				<Box gap10 center>
					<Box h1>Comienza Tu Aventura en Habbux</Box>
					<Box p font9 textcenter>¡Todo está listo para tu gran debut! Estás a punto de sumergirte en una comunidad llena de creatividad, amistad y diversión. Revisa tu correo electrónico para la verificación final, y ya estarás listo para explorar, interactuar y dejar tu huella en Habbux Hotel. ¡Nos vemos dentro!</Box>
				</Box>
			</Box>
			{/* <Form submit="auth/register" gap5 center>
				<BigButton>¡Listo!, ¡Comenzemos ya!</BigButton>
				{children}
			</Form> */}
			<Image absolute right="5%" bottom="0" src={Frank}></Image>
		</Box>
	);
}

export function Percent({ value = 0, symbol = '%' }) {
	return (
		<Box background="linear-gradient( 90deg, #122b3c, #1c1c1c )" border="1px solid #fff" width="400px" height="25px" margin="0px 47px" padding="3px" radius13>
			<Box fullh background="linear-gradient(#bfd1d9 50%,#93acb7 0)" transition="all ease 1s" width={value} />
			<Box absolute center>
				{value}{symbol}
			</Box>
		</Box>
	);
}