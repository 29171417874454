import React, { ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { useAxios } from '@/hooks/use-axios';
import { Box, BoxProps } from '@/common/layout/box';

export type FormProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;

	onBefore?: () => void;

	onStream?: (data?: any) => void;

	/**
	 * Validation is triggered on the changeevent for each input, leading to multiple re-renders. Warning: this often comes with a significant impact on performance.
	 */
	onChange?: (event: React.FormEvent, data?: any) => void;

	/**
	 * Validation is triggered on the submit event, and inputs attach onChange event listeners to re-validate themselves.
	 */
	onSubmit?: (event: React.FormEvent, data?: any) => void;

	onProgress?: (data?: any) => void;
	onError?: (data?: any) => void;
	onCancel?: (data?: any) => void;
	onSuccess?: (data?: any) => void;

	/**
	 * Method for setting data of the form
	 */
	values?: any;

	/**
	 * Setting data to the form
	 */
	include?: any;

	/**
	 * Redirect route on success
	 */
	route?: string;

	/**
	 * Url where submit
	 */
	submit?: string;

	/**
	 * XHR Method
	 */
	method?: 'post' | 'get' | 'POST' | 'GET';

	/**
	 * Method for setting data of the form
	 */
	callback?: any;
} & Omit<React.HTMLAttributes<HTMLFormElement>, 'onSubmit'> & BoxProps;

export const Context = createContext<any>({});

export function Form({
	children,
	onBefore,
	onStream,
	onChange,
	onSubmit,
	onProgress,
	onError,
	onCancel,
	onSuccess,
	route,
	submit,
	values,
	include,
	callback,
	...rest }: FormProps) {

	const [errors, setErrors] = useState(false);

	onSubmit = (e: React.FormEvent, Formdata?: any) => {
		if (include) {
			for (const [key, value] of Object.entries(include)) {
				Formdata[key] = value;
			}
		}
		values && values(Formdata);
		if (submit) {
			Axios.setData(Formdata);
			Axios.post();
		}
	};

	onSuccess = (data) => {
		setErrors(false)
		callback && callback(data)
		if (route) {
			Axios.setUrl(route);
			Axios.post();
		}
	};

	onError = (errors) => {
		setErrors(errors)
	}

	const Axios = useAxios({
		url: submit,
		onStream: onStream,
		onSuccess: onSuccess,
		onError: onError,
		onUploadProgress: onProgress,
		onDownloadProgress: onProgress
	});

	return (
		<Box as="form"
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit && onSubmit(e, extractFormData(e))
			}}
			onChange={(e) => {
				const target = e.target as HTMLInputElement;
				const nextValue = { [target.name]: target.value }
				onChange && onChange(e, { target, ...nextValue })
			}}
			{...rest}
		>
			<Context.Provider value={ { errors } } >
				{ children }
			</Context.Provider>
		</Box>
	);
}

/**
 * a method to extract form elements data from within a form element's submit event
 */
export function extractFormData(event: React.FormEvent<HTMLFormElement>) {
    const form = event.currentTarget;
    const formData = new FormData(form);
    const dataToSubmit: any = {};

    // Agregar los valores habilitados
    for (let [key, value] of formData) {
        if (!key) continue;
        dataToSubmit[key] = value;
    }

    // Agregar manualmente los valores deshabilitados
    const disabledElements = form.querySelectorAll('[disabled]');
    disabledElements.forEach(el => {
        if (el.name && !dataToSubmit.hasOwnProperty(el.name)) {
            dataToSubmit[el.name] = el.value;
        }
    });

    return dataToSubmit;
}