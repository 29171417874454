import React, { ReactNode } from 'react';
import { useImageSize } from './image-size';
import { Box, BoxProps } from '@/common/layout/box';

export type ImageProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;

	/**
	 * if true, returns a div with the image as a background image.
	 */
	Background?: any;

	/**
	 *  Informative elements should aim for short, descriptive alternate text. Decorative elements can be ignored with an empty alt attribute.
	 */
	alt?: string;

	src?: any;

	defaultsrc?: any;

	auto?: boolean;

	size?: string;
} & BoxProps;

export function Image({ children, defaultsrc, src = '', alt = '', width, height, size, auto, shrink, overflow, ...rest }: ImageProps) {
	if (!src) return null;

	width = size ? size : width ? width : "auto"
	height = size ? size : height ? height : "auto"

	if (!auto) {
		const [dimensions, { loading, error }] = useImageSize(src);
		if (dimensions) {
			if (dimensions.width && width == "auto") {
				width = dimensions.width + 'px';
			}
			if (dimensions.height && height == "auto") {
				height = dimensions.height + 'px';
			}
		}
		
	}
	
	return (
		<Box
			as="img"
			src={src}
			alt={alt}
			width={width}
			height={height}
			loading="lazy"
			pointerevents="none"
			onError={(e) => {
				e.target.onError = null;
				e.target.src = defaultsrc;
			}}
			shrink={1}
			maxheight="100%"
			maxwidth="100%"
			basis="100%"
			userselect="none"
			overflow="hidden"
			{...rest}
		></Box>
	);
}
