import { Box } from '@/common/layout/box';
import { Link } from '@/common/navigation/link';
import React, { ReactNode } from 'react';

export type TermsOfServiceProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function TermsOfService({ children }: TermsOfServiceProps) {
	return (
		<Box gap="10px">
			<Box placeitems="center"><h1>Términos y Condiciones</h1></Box>
			<Box>
			Al igual que con todos los hoteles privados, el nuestro no sería diferente, los Términos y Condiciones de Habbux Hotel Deben leerse detenidamente antes de registrarse en el sitio / juego. Los Términos y Condiciones tienen su valor determinado, para el correcto uso de nuestro sitio web al que proporciona algunos parámetros.
			</Box>
			
			<Box>
			<strong>Habbux Hotel</strong>Cubre una gran cantidad de usuarios y, por lo tanto, es necesario asegurarlos todos de manera confiable y eficiente. De esta manera, la <Link href="/habbux-way">Manera Habbux</Link>se integra en la rutina de cada uno esencialmente, pero no solo. Por lo tanto, ¡tendremos que ir más allá de la habitación!
			</Box>
            
			<Box>
			Este sitio es operado por Habbux Hotel. En todo el sitio, los términos "nosotros", "nos" y "nuestro" se refieren a Habbux Hotel. Proporciona en este sitio web, incluyendo toda la información, herramientas y servicios que este sitio web pone a su disposición, el usuario, con la condición de que acepte todo lo aquí expresado..
			</Box>
			
			<Box>
            El Sitio Web y los Servicios están pensados para mayores de catorce (14) años. Si tienes menos de dieciocho (18) años y más de catorce (14) años, pide a tus padres, tutores o guardadores que revisen contigo la <Link href='*' >Política de Privacidad</Link>, y consulta con ellos cualquier circunstancia de nuestra <Link href='*' >Política de Privacidad</Link>que no entiendas, para que te expliquen su significado. En todo caso, ninguna persona cuya edad se encuentre por debajo de catorce (14) años está autorizada para facilitarnos datos personales o publicar datos personales dentro del Sitio Web o de los Servicios.
			Al cumplimentar el formulario de registro garantizas que eres mayor de catorce (14) años y serás enteramente responsable de esta declaración.
			</Box>
			
			<Box><h1>1. Acceso y uso del sitio web</h1></Box>
			<Box>
			1.1 Para usar los Servicios, es necesario que te registres como usuario en el Sitio Web (en adelante, 'Usuario Registrado'). Para registrarte deberás proporcionar información completa, actual y veraz sobre ti, que será tratada de acuerdo con nuestra Política de Privacidad. Es tu responsabilidad mantener tus datos de registro actualizados e informarnos sobre cualquier cambio relativo a dicha información, enviándonos un correo electrónico.
			</Box>
			
			<Box>
			1.2 Eres responsable de la confidencialidad, seguridad y uso apropiado de tu nombre de usuario y contraseña de Usuario Registrado, y te comprometes a no revelar ni poner a disposición de ninguna otra persona tu nombre de usuario y/o contraseña, y a no permitir a nadie el acceso a tu cuenta de Usuario Registrado. Eres responsable de todas las actividades que ocurran bajo tu cuenta y contraseña. Si sospechas de cualquier uso no autorizado de tu cuenta o de tu nombre de usuario o de tu contraseña deberás notificarlo a Sulake de inmediato. Igualmente, si olvidas o pierdes una contraseña o nombre de usuario deberás contactarnos y cumplir todos los requisitos de seguridad que te indiquemos. Asimismo, te comprometes a no crear más de una cuenta de Usuario Registrado dentro del Sitio Web.
			</Box>
			
			<Box>
			1.3 Queda terminantemente prohibido y te comprometes a no solicitar y/o utilizar el nombre de usuario o la contraseña de otro Usuario Registrado para acceder al Sitio Web o a los Servicios, con o sin el permiso del Usuario Registrado en cuestión.
			</Box>
			
			<Box>
			1.4 La elección de tu nombre de usuario o contraseña queda sujeta a la aprobación por parte de Habbux. Asimismo, Habbux se reserva todos los derechos sobre el nombre de usuario y contraseña que finalmente hayas escogido, así como la posibilidad de suspender o pedirte que cambies tu nombre de usuario o tu contraseña en cualquier momento, en particular pero sin limitación, si consideramos que hay, o es posible que haya, un fallo en la seguridad derivado de los mismos.
			</Box>
			
			<Box>
			1.5 La prestación del Sitio Web o de los Servicios no incluye la puesta a tu disposición de un ordenador o de cualquier otro equipamiento. Para usar el Sitio Web y los Servicios necesitarás una conexión a Internet y los equipos informáticos y de telecomunicaciones adecuados.
			</Box>
		    <Box><h1>2. Uso del sitio web y de los servicios</h1></Box>
			
			<Box>
			2.1 El Sitio Web y los Servicios están a tu disposición única y exclusivamente para tu uso con fines personales y privados, no para usos comerciales o lucrativos.
			</Box>
			
			<Box>2.2 El Sitio Web contiene un Servicio de chat instantáneo que te permitirá hablar con otros Usuarios Registrados (en adelante, las 'Salas de Chat'), tablones de mensajes donde podrás publicar tus mensajes (en adelante, los 'Tablones de Mensajes'), y páginas personales donde podrás publicar tus perfil, mensajes y otros contenidos, que otros usuarios podrán ver (en adelante, las 'Páginas Personales'). Te comprometes a utilizar estos Servicios del Sitio Web sólo para publicar, difundir, comunicar, divulgar, enviar y recibir mensajes y material que sean correctos y educados, cumpliendo con los términos de este Contrato y especialmente con la Manera Habbux, así como de acuerdo con la legislación aplicable y a los usos y buenas costumbres. Eres el único responsable por toda la información, encuestas, resultados, fotografías, mensajes y cualquier otro material o contenido, de cualquier tipo, que publiques, difundas, comuniques, divulgues o pongas a disposición en o a través del Sitio Web o de los Servicios (en adelante, 'Tu Contenido'), y de las consecuencias que la publicación o comunicación de Tu Contenido en el Sitio Web pudiera tener. En particular pero sin limitación, se prohíbe utilizar el Sitio Web o los Servicios, y te comprometes a NO utilizar el Sitio Web o los Servicios, con o para alguna de las siguientes finalidades:
			</Box>
			
			<Box>
			2.2.1 Usar las Salas de Chat, los Tablones de Mensajes, las Páginas Personales y en general los Servicios en relación con encuestas, concursos, mensajes piramidales o encadenados, emails o SMS basura, o cualquier otro tipo de mensaje o comunicación no solicitada ('spam'), sean de carácter comercial o de cualquier otro tipo.
			</Box>
			
			<Box>
			2.2.2 Usar las Salas de Chat, los Tablones de Mensajes, las Páginas Personales y en general los Servicios para organizar o practicar cualquier tipo juegos de suerte, envite o azar cuya participación implique dinero u objetos valorables económicamente (apuestas, sorteos, casinos).
			</Box>
			
			<Box>
			2.2.3 Difamar, abusar, molestar, acosar, amenazar o violar de cualquier otra manera derechos (tales como el derecho a la intimidad, al honor y a la propia imagen, o el derecho a la libertad de expresión) de otras personas.
			</Box>
			
			<Box>
			2.2.4 Publicar, difundir, comunicar, divulgar, enviar, recibir, descargar, distribuir o de cualquier forma poner a disposición cualquier material, contenido o información que: (a) es o pueda ser ilegal o no conforme con la normativa aplicable, o que promueva actividades ilegales o no conformes con la normativa aplicable; (b) es pornográfico, sexualmente explícito, violento u obsceno; © solicite información personal ; (d) es racista o fomente el racismo, el odio o la comisión de crímenes hacia otra persona o grupos de personas; (e) es amenazante, abusivo o dañino con respecto a una persona o grupos de personas; (f) es difamatorio, injurioso o calumnioso; (g) constituye un falso testimonio sobre una persona o su propiedad o su negocio de forma maliciosa y causando daño financiero; (h) es engañoso o fraudulento; (i) vulnera la confianza o invade el derecho a la intimidad de las personas; (j) lleve a que Sulake se vea envuelta en una infracción de la ley, de un código o de cualquier instrumento, norma o regulación aplicable; (k) pueda dar lugar a cualquier tipo de responsabilidad en contra de Sulake; (l) de cualquier otra forma, vulnere derechos de cualquier otra persona; o (m) de cualquier otra forma, infrinja la Manera Habbux o cualquier otro código o política establecida por Habbux.
			</Box>
			
			<Box>
			2.2.5 Publicar, difundir, comunicar, enviar o de cualquier otra forma poner a disposición archivos, materiales o contenidos que contengan software u otro material protegido por derechos propiedad intelectual o industrial, patentes, marcas, nombres comerciales, derechos de imagen, o derecho a la intimidad, a no ser que seas titular o controles dichos derechos o hayas recibido la autorización necesaria para ello.
			</Box>
			
			<Box>
			2.2.6 Publicar, difundir, comunicar, enviar o de cualquier otra forma poner a disposición virus, gusanos, troyanos, archivos corruptos, o cualquier otro software similar o programas que puedan dañar el Sitio Web, los Servicios o los equipos informáticos o la propiedad de Sulake o de cualquier otra persona.
			</Box>
			
			<Box>
			2.2.7 Enviar, difundir o incluir publicidad o comunicaciones comerciales, referenciar enlaces o realizar ofertas de compra o venta de bienes o servicios a los usuarios del Sitio Web.
			</Box>
			
			<Box>
			2.2.8 Restringir o impedir a cualquier otro usuario el uso y disfrute de las Salas de Chat, los Tablones de Mensajes, las Páginas Personales y en general de los Servicios y del Sitio Web.
			</Box>
			
			<Box>
			2.2.9 Vulnerar cualquier código de conducta o cualquier regulación que sea aplicable a un Servicio o contenido en particular.
			</Box>
			
			<Box>
			2.2.10 Recopilar y almacenar datos personales de otras personas, incluyendo direcciones de email, o publicar datos o información personal de otra persona.
			</Box>
			
			<Box>
			2.2.11 Vulnerar, o promover la infracción de, cualquier ley o regulación aplicable, o los derechos de un tercero.
			</Box>
			
            <Box>
			2.2.12 Crear una identidad falsa, o suplantar a cualquier persona o identidad, con el propósito de confundir a otras personas.
			</Box>
			<Box><h1>Propidad intelectual e industrial</h1></Box>
			
			<Box>
				3.1 Mediante la publicación de Tu Contenido en el Sitio web o en los Servicios concedes a Sulake, a las empresas del grupo al que Sulake pertenece, y a nuestros subcontratistas, una licencia irrevocable, por el tiempo máximo establecido en la ley, no exclusiva, transferible (incluido la posibilidad de otorgar sublicencias), libre de derechos y mundial para utilizar, copiar, ejecutar, editar, modificar, revelar, publicar, difundir y distribuir Tu Contenido, y cualesquiera datos, imágenes, sonidos, textos y otros elementos incluidos en el mismo, tanto a través del Sitio Web o de los Servicios como en otros medios, incluido en medios publicitarios, así como para crear obras derivadas y para modificar Tu Contenido a fin de adaptarlo al Sitio web.
			</Box>
			{children}
		</Box>
	);
}
