import React, { ReactNode, useContext, useState } from 'react';
import { useData } from '@/context/data';
import { Box } from '@/common/layout/box';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { Responsive, WidthProvider } from "react-grid-layout";
import { useAxios } from '@/hooks/use-axios';

// const ResponsiveGridLayout = WidthProvider(Responsive);

export type DashboardProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
};

const chdata = [
	{
	  name: 'Page A',
	  uv: 4000,
	  pv: 2400,
	  amt: 2400,
	},
	{
	  name: 'Page B',
	  uv: 3000,
	  pv: 1398,
	  amt: 2210,
	},
	{
	  name: 'Page C',
	  uv: 2000,
	  pv: 9800,
	  amt: 2290,
	},
	{
	  name: 'Page D',
	  uv: 2780,
	  pv: 3908,
	  amt: 2000,
	},
	{
	  name: 'Page E',
	  uv: 1890,
	  pv: 4800,
	  amt: 2181,
	},
	{
	  name: 'Page F',
	  uv: 2390,
	  pv: 3800,
	  amt: 2500,
	},
	{
	  name: 'Page G',
	  uv: 3490,
	  pv: 4300,
	  amt: 2100,
	},
  ];

export function Dashboard({ children }: DashboardProps) {
	const { data, fetch } = useData();

	const Layout = useAxios({
		url: '/api/admin/widget',
		onSuccess: (data?: any) => {
			Layout.setData(JSON.parse(data).layout)
		}
	}, true);

	const setLayout = useAxios({
		url: '/api/admin/widget/save'
	});

	const onLayoutChange = (layout: any) => {
		console.log(JSON.stringify({
			"layout": layout
		}))
		setLayout.setData(layout);
		setLayout.post();
	}

	return (
		<Box gap="1.5rem">
			<Box color="white">
				<h2>Bienvenido { data.user.username }</h2>
				<p>Haga uso del panel de control manteniendose dentro margen de las normas establecidas para y con la comunidad.</p>
			</Box>
			{children}
		</Box>
	);
}
