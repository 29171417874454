import React, { useState } from 'react';

export function useToggle(initialValue: any) {
	const [active, setActive] = useState(initialValue)

	const toggle = () => {
		setActive(!active)
	}

	return [active, toggle, setActive];
}