import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Image } from '@/common/content/image';
import logo from './images/logo.png';
import { Link } from '@/common/navigation/link';
import { BoxProps } from '@/common/layout/box';

export type LogoProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;
} & BoxProps;

export function Logo({ children, ...props }: LogoProps) {
	return (
		<Link href="/" font5 {...props}>
			DII
		</Link>
	);
}