import React, { createContext, ReactNode, useContext } from 'react';
import { useMergeState } from '@/hooks/use-merge-state';
import { useAxios } from '@/hooks/use-axios';

export type ContextProps = {
	children?: ReactNode;
	value?: object;
};

type ContextType = {
	data: any | null;
	fetch: any;
	setData: any;
};

const defaultContextValue: ContextType = {
	data: null,
	fetch: () => { },
	setData: () => { },
};

export const DataContext = createContext<ContextType>(defaultContextValue);

export function DataProvider({ children, value = {}, ...props }: ContextProps) {
	const [data, setData] = useMergeState(value);

	const fetch = useAxios({
		url: '/info',
		onSuccess: setData,
		// interval: 1000
	}, true);

	return (
		<DataContext.Provider value={{ data, fetch, setData }}>
			{children}
		</DataContext.Provider>
	);
}

export function useData() {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error('useData must be used within a DataProvider');
	}
	return context;
}