import { Box, BoxProps } from '@/common/layout/box';
import { useAxios } from '@/hooks/use-axios';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export type ButtonProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children?: ReactNode;

	/**
	 * link to target layouts/ once href is used, Button is considered an A tag.
	 */
	href?: string;

	onClick?: any;

	/**
	 * explicitly toggle loader on and off
	 */
	disabled?: boolean;

	/**
	 * allow clicking on button even while loading. (i.e. make simultaneous triggers)
	 */
	activeWhenLoading?: boolean;
} & BoxProps;

export function Button({ children, href, onClick, activeWhenLoading, ...rest}: ButtonProps) {
	const [isLoading, setLoading] = useState(false);
	const activePromises = new Set<Promise<any>>();
	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (href) {
            if (/^(http|https):\/\//.test(href)) {
                window.open(href, '_blank');
            } else {
                navigate(href);
            }
            return;
        }

		if (!onClick) return;

		setLoading(true);

		const promise = Promise.resolve(onClick(event)).catch(() => {});
		activePromises.add(promise);

		promise.then(() => setLoading(false));
	};

	return (
		<Box as="button" name="" background="none" width="max-content" height="max-content" cursor="pointer" shrink={0}
			disabled={(isLoading || activeWhenLoading)}
			onClick={handleClick}
			{...rest}
		>
			{children}
		</Box>
	);
}

Button.defaultProps = {
	disabled: false,
	activeWhenLoading: false
}