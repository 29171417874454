import { Box } from '@/common/layout/box';
import { useAxios } from '@/hooks/use-axios';
import { useMergeState } from '@/hooks/use-merge-state';
import React, { ReactNode } from 'react';

export type RegistryProps = {
	/**
	 * A node to be rendered in the special component.
	 */
	children?: ReactNode;
};

export function Registry({ children }: RegistryProps) {
	const [data, setData] = useMergeState({});
	
	const CommandLogList = useAxios({
		url: 'logs/commands',
		onSuccess: setData
	}, true);

	return (
		<Box background="white" radius="10px" height="100%">
			<Box as="table" display="table"
				styles={`
				& {
					border-spacing: 0;
				}
				& thead th {
					padding: 10px;
					border-bottom: 1px solid rgba(0,0,0,.3);
					text-align: left;
				}
				& tfoot th {
					padding: 10px;
					border-top: 1px solid rgba(0,0,0,.3);
					text-align: left;
				}
				& tbody td {
					padding: 8px 10px;
					border-top: 1px solid rgba(0,0,0,.15);
				}
				& tbody tr:first-child td {
					border-top: none;
				}
				& tbody>tr.odd>* {
					box-shadow: inset 0 0 0 9999px rgba(0,0,0,.023);
				}
				& tbody>tr:hover>* {
					box-shadow: inset 0 0 0 9999px rgba(0,0,0,.035);
				}
			`}>
				<thead>
					<tr>
						{data[0] && Object.keys(data[0].data).map((item, k) => (
							<th>{item}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{Object.keys(data).slice(0, 5).map((key, i) => (
					<tr>
						{Object.keys(data[key].data).map((item, k) => (
							<td className={i % 2 == 0 ? '' : 'odd'}>{data[key].data[item]}</td>
						))}
					</tr>
					))}
				</tbody>
				<tfoot>
					<tr>
						{data[0] && Object.keys(data[0].data).map((item, k) => (
							<th>{item}</th>
						))}
					</tr>
				</tfoot>
				{children}
			</Box>
			<Box row>
				<Box>Mostrando desde 20803 a 20807 de 20807 entradas</Box>
				<Box row col place="end">"Paginación"</Box>
			</Box>
		</Box>
	);
}
